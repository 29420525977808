var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-top detail-total detail-total__new"},[(!_vm.isWaitingforPayment)?_c('div',{staticClass:"detail-title mshe-flexbetween"},[_c('span',[_c('span',[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_15096)+": "+_vm._s(_vm.orderInfo.totalPrice.amountWithSymbol)+" \n      ")]),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.orderInfo.payable && _vm.orderInfo.payable.amount > 0)?_c('span',[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_15360)+" "+_vm._s(_vm.orderInfo.payable.amountWithSymbol)+"\n      ")]):(_vm.orderInfo.isLocalCurrency)?_c('span',[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_16040)+": "+_vm._s(_vm.orderInfo.localCodPrice.amountWithSymbol)+"\n      ")]):_vm._e()]),_vm._v(" "),_c('em',{class:['detail-price__detail', { 'detail-edit-payment': _vm.locals.lang == 'de' }],on:{"click":function($event){_vm.pageStatus.paymentDetailDrawer = true}}},[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_15372)+"\n      "),_c('sui_icon_more_right_16px',{staticClass:"detail-price__icon",attrs:{"size":"16px","is-rotate":_vm.locals.GB_cssRight}})],1)]):_vm._e(),_vm._v(" "),(_vm.isWaitingforPayment)?[_c('shipping-billing-address-detail',{attrs:{"order-info":_vm.orderInfo,"hidden-billing-address":_vm.paymentMethod.paymentType == 2,"customerItemSyncToUserAddressAbt":_vm.customerItemSyncToUserAddressAbt,"canShrinkBillingAddress":_vm.isUnPaiMoreAddressOrder,"newOrderInfo":_vm.newOrderInfo,"isUnPaiMoreAddressOrder":_vm.isUnPaiMoreAddressOrder}},[_c('div',{staticClass:"info",attrs:{"slot":"header"},slot:"header"},[_c('h6',[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_15145))]),_vm._v(" "),_c('p',{staticClass:"mshe-flexbetween"},[_c('span',{staticClass:"mshe-flexbetween"},[(_vm.bankLogo || _vm.paymentMethod.paymentLogo)?[_c('img',{staticClass:"payment",attrs:{"src":_vm.transformImg({ img: _vm.bankLogo || _vm.paymentMethod.paymentLogo })}})]:_vm._e(),_vm._v(" "),(
                _vm.paypalGaVault &&
                  _vm.paypalGaVault.valid &&
                  _vm.paypalGaVault.type == '1' &&
                  _vm.paymentMethod.paymentMethod == 'PayPal-GApaypal'
              )?_c('span',[_vm._v("\n              "+_vm._s((_vm.ppgvAccountInfo && _vm.ppgvAccountInfo.signUpEmail) || _vm.language.SHEIN_KEY_PWA_19684)+"\n            ")]):(_vm.bankCodeText)?_c('span',[_vm._v("\n              "+_vm._s(_vm.bankCodeText)+"\n            ")]):_c('span',[_vm._v("\n              "+_vm._s(_vm.paymentMethod.paymentTitle)+"\n            ")])],2),_vm._v(" "),(_vm.orderInfo.payment_method != 'cod')?_c('s-button',{staticClass:"edit-payment",attrs:{"type":['H48PX'],"DA-view-type":"ordinary","DA-type":"syncClick","DA-sa-name":"payment_method_edit"},on:{"click":function($event){return _vm.toEditPayment()}}},[_vm._v("\n            "+_vm._s(_vm.showNewAddressBtn ? _vm.language.SHEIN_KEY_PWA_23929: _vm.language.SHEIN_KEY_PWA_16132)+"\n          ")]):_vm._e()],1),_vm._v(" "),(
            _vm.paymentDisplay(_vm.paymentMethod.paymentMethod) &&
              (_vm.cardBinRandomDiscountInfo || _vm.cardBinInitDiscountInfo || _vm.paymentText)
          )?_c('preferential-copy-block',{staticClass:"mt-6",attrs:{"preferential-text":_vm.cardBinInitDiscountInfo,"randomDiscountText":_vm.cardBinRandomDiscountInfo,"paymentText":_vm.paymentText}}):_vm._e()],1),_vm._v(" "),(_vm.isUnPaiMoreAddressOrder)?_c('div',{staticClass:"shipping-methods__wrap",attrs:{"slot":"shippingMethodTabs"},slot:"shippingMethodTabs"},[_c('s-tab',{attrs:{"type":'auto'},on:{"change":_vm.handleTabChange},model:{value:(_vm.methodTabSelected),callback:function ($$v) {_vm.methodTabSelected=$$v},expression:"methodTabSelected"}},_vm._l((_vm.addressList),function(item,index){return _c('s-tab-item',{key:index,attrs:{"id":index}},[_vm._v("\n            "+_vm._s(item.shipping_method)+"\n          ")])}),1)],1):_vm._e(),_vm._v(" "),(_vm.showEditOrderAddress)?_c('EditAddressBtnNew',{attrs:{"slot":"edit-shipping-address","editAddressNewConfigList":_vm.editAddressNewConfigList,"order":_vm.newOrderInfo,"language":_vm.language,"button-style":['H48PX'],"show-short":_vm.showNewAddressBtn,"newEditAddressOptions":_vm.newEditAddressOptions,"newOrderInfo":_vm.newOrderInfo},on:{"showEditAddressDialog":_vm.showEditAddressDialogEvt},slot:"edit-shipping-address"},[_c('i',{staticClass:"suiiconfont sui_icon_edit_20px"})]):[(
            (_vm.editAddressFlag(_vm.orderInfo) == 1 && _vm.orderType != 'oldorder' && _vm.showPartOperatorBtn) ||
              [2, 3].includes(_vm.deliveryEditAddressCond)
          )?_c('div',{staticClass:"address-edit mshe-fr",attrs:{"slot":"edit-shipping-address"},slot:"edit-shipping-address"},[(_vm.showNewAddressBtn)?_c('s-button',{class:{
              'disabled':
                _vm.overFrontCondition > 0 ||
                _vm.deliveryEditAddressCond == 2 ||
                _vm.overB2B2CEditAddress ||
                _vm.disabledEditAddress
            },attrs:{"type":['H48PX'],"DA-view-type":"ordinary","DA-type":"syncClick","DA-sa-name":"shipping_address_edit","da-event-click":"1-19-1-2"},on:{"click":_vm.toClickEditAddress}},[_vm._v("\n            "+_vm._s(_vm.language.SHEIN_KEY_PWA_23929)+"\n          ")]):_c('i',{staticClass:"suiiconfont sui_icon_edit_20px",class:{
              'btn-disabled':
                _vm.overFrontCondition > 0 ||
                _vm.deliveryEditAddressCond == 2 ||
                _vm.overB2B2CEditAddress ||
                _vm.disabledEditAddress
            },attrs:{"DA-view-type":"ordinary","DA-type":"syncClick","DA-sa-name":"shipping_address_edit","da-event-click":"1-19-1-2"},on:{"click":_vm.toClickEditAddress}})],1):_vm._e()],_vm._v(" "),(_vm.orderInfo.isCanEditBillingAddressByUser == 1 && _vm.editBillAddressFlag)?_c('div',{staticClass:"address-edit mshe-fr",attrs:{"slot":"edit-billing-address"},slot:"edit-billing-address"},[(_vm.showNewAddressBtn)?_c('s-button',{attrs:{"type":['H48PX'],"DA-view-type":"ordinary","DA-type":"syncClick","DA-sa-name":"billing_address_edit","da-event-click":"1-19-1-2"},on:{"click":_vm.toClickEditBillAddress}},[_vm._v("\n          "+_vm._s(_vm.language.SHEIN_KEY_PWA_23929)+"\n        ")]):_c('i',{staticClass:"suiiconfont sui_icon_edit_20px",attrs:{"DA-view-type":"ordinary","DA-type":"syncClick","DA-sa-name":"billing_address_edit","da-event-click":"1-19-1-2"},on:{"click":_vm.toClickEditBillAddress}})],1):_vm._e()],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }