<template>
  <div 
    class="product-repurchase"
    :class="{ 'has-coupon': !!repurchaseInfo }"
  >
    <div class="product-repurchase__content">
      <div 
        v-if="repurchaseInfo"
        class="inducement-info"
      >
        <img 
          class="inducement-info__icon"
          :src="transformImg({ img: repurchaseInfo.icon })" 
        />
        <div 
          class="inducement-info__text"
          v-html="repurchaseInfo.tip"
        >
        </div>
      </div>
    </div>
    <div
      v-tap="{ id: '1-19-1-152', data: analysisData }"
      v-expose="{ id: '1-19-1-153', data: analysisData }"
      class="add-cart-btn"
      @click.stop="addToCart"
    >
      <sui_icon_addtocart_20px
        size="20px"
        class="add-cart-btn__icon"
      />
    </div>
  </div>
</template>

<script>
import schttp from 'public/src/services/schttp'
import { mapMutations, mapState } from 'vuex'
import { transformImg } from '@shein/common-function'
import { sui_icon_addtocart_20px } from '@shein-aidc/icon-vue2'

export default {
  name: 'ProductRepurchase',
  components: {
    sui_icon_addtocart_20px,
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'orderInfo', 
      'language', 
      'orderResultAbtInfo',
    ]),
    isMultiGoods() {
      const { orderGoodsList = [] } = this.orderInfo
      return orderGoodsList.flat(Infinity).length > 1
    },
    orderRepurchaseParam() {
      return this.orderResultAbtInfo?.orderRepurchaseParam || {}
    },
    // 显示商品层级的利诱信息
    showProductRepurchase() {
      const { PromosPosition = '' } = this.orderRepurchaseParam
      return Boolean(PromosPosition?.includes('detail_product'))
    },
    // 回购成功显示新的成功页
    showNewRepurchaseSuccess() {
      const { NewRepurchase = '' } = this.orderRepurchaseParam
      return NewRepurchase === 'on'
    },
    // 商品行利诱信息
    repurchaseInfo () {
      // 没有开启abt或者单品商品行不显示利诱
      if (!this.isMultiGoods || !this.showProductRepurchase) return null
      const { tip, icon } = this.product.repurchase_lure_info || {}
      if (icon && tip) {
        return this.product.repurchase_lure_info
      }
      return null
    },
    analysisData() {
      return { 
        goods_id: this.product.goods_id,
        is_promoshow: this.repurchaseInfo ? 1 : 0,
        position: 'product'
      }
    },
  },
  methods: {
    ...mapMutations('root', ['changeRootStatus']),
    ...mapMutations('orderDetail', [
      'assignState',
    ]),
    transformImg,
    async addToCart() { 
      const goodsId = this.product.product.goods_id
      const productData = {
        goods_id: goodsId,
        mall_code: this.product.mall_code,
        sku_code: this.product.sku_code,
        quantity: +this.product.quantity || 1,
        trace_id: gbExposeTraceid('getProduct', {
          goods_id: goodsId
        }),
        add_source: 19
      }
      this.changeRootStatus({ loading: true })
      const res = await schttp({
        url: '/api/cart/add_mall/create',
        method: 'POST',
        data: productData
      })
      this.changeRootStatus({ loading: false })
      if (res.code == 0) {
        if (this.showNewRepurchaseSuccess) {
          this.assignState({
            addCartStatus: {
              showDrawer: true,
              cartInfo: {
                successInfoList: [
                  {
                    goods_thumb: this.product.product.goods_img,
                    sku_code: this.product.sku_code
                  }
                ],
                falidInfoList: [],
                repurchaseInfo: this.repurchaseInfo
              },
            }
          })
        } else {
          this.$emit('handleShowSuccessPanel', { from: 'orderDetail' })
        }
      } else if (res.code == 300373) {
        this.$toast(this.language.SHEIN_KEY_PWA_15551)
      } else {
        this.$toast(res.tips || this.language.SHEIN_KEY_PWA_15409)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.product-repurchase {
  display: flex;
  align-items: center;
  padding: 6/75rem 8/75rem;
  margin-top: 20/75rem;
  
  &.has-coupon {
    background: @sui_color_welfare_bg;
  }
 
  &__content {
    flex: 1;
    overflow: hidden;
  }

  .add-cart-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 16/75rem;
    border-radius: 60/75rem;
    border: 1px solid @sui_color_brand;

    &__icon {
      color: @sui_color_brand;
    }
  }
}

.inducement-info {
  display: flex;
  align-items: center;
  padding: 4/75rem 8/75rem;
  overflow: hidden;
  color: @sui_color_welfare;
  background: @sui_color_welfare_bg;

  &__icon {
    flex-shrink: 0;
    width: 24/75rem;
    height: 24/75rem;
  }

  &__text {
    flex: 1;
    font-size: 10px;
    margin-left: 8/75rem;
    word-break: break-word;
    .line-camp(2);
  }
}
</style>
