<template>
  <div class="order-edit-pay">
    <slot name="header"></slot>
    <slot name="shippingMethodTabs"></slot>
    <div class="info">
      <div class="title"> 
        {{ language.SHEIN_KEY_PWA_15081 }} 
      </div>
      <div class="detail-shipping-flexbetween info-item">
        <span>
          <span class="name-phone">
            <span class="name">
              {{ sortAddressCardInfo({ detail: orderInfo, fromType: 'shipping' }) }}
            </span>
            <span class="phone">
              {{ orderInfo.shipping_telephone }}
            </span>
          </span>
          <span class="address-content">
            <span
              v-if="isShowStoreIcon"
              class="store-icon"
            >
              <sui_icon_me_shop_15px 
                size="16px" 
                color="#198055" 
              />
            </span>
            <span>
              <span v-if="orderInfo.shipping_country_id == '108'">
                〒 {{ orderInfo.shipping_postcode }}
              </span>
              <span v-else>
                <template
                  v-if="shipTransitCountry && (orderInfo.subsidiary && orderInfo.subsidiary.store_id)"
                >
                  {{ orderInfo.subsidiary.store_name }}
                  <template v-if="orderInfo.shipping_country_id == '209'">{{
                    orderInfo.subsidiary && orderInfo.subsidiary.store_type == 1
                      ? '(7-ELEVEN)'
                      : '(全家便利商店)'
                  }}</template>
                </template>
                <template
                  v-if="!(orderInfo.shipping_country_id == '209' && (orderInfo.subsidiary && orderInfo.subsidiary.store_id))"
                >
                  {{ orderInfo.shipping_address_1 }}
                  {{ orderInfo.shipping_address_2 }}
                  <template v-if="orderInfo.shipping_country_id == '209' && orderInfo.type == '3'">
                    （{{ language.SHEIN_KEY_PWA_17662 }}）
                  </template>
                </template> 
              </span>
              <br />
              <span v-if="orderInfo.shipping_country_id == '108'">
                {{ orderInfo.shipping_country_name }}
                {{ orderInfo.shipping_province }}
                {{ orderInfo.shipping_city }}
                {{ orderInfo.shipping_district }}
                {{ orderInfo.shipping_street }}
                {{ orderInfo.shipping_address_1 }}
                {{ orderInfo.shipping_address_2 }}
              </span>
              <span v-else>
                <template
                  v-if="orderInfo.shipping_country_id == '209' && (orderInfo.subsidiary && orderInfo.subsidiary.store_id)"
                >
                  {{ orderInfo.shipping_address_1 }}
                  {{ orderInfo.shipping_address_2 }}
                </template>
                {{ orderInfo.shipping_street }}
                {{ [orderInfo.shipping_district, secondLanguage.district].filter(Boolean).join('/') }}
                {{ [orderInfo.shipping_city, secondLanguage.city].filter(Boolean).join('/') }}
                {{ [orderInfo.shipping_province, secondLanguage.state].filter(Boolean).join('/') }}
                {{ orderInfo.shipping_country_name }}
                {{ orderInfo.shipping_postcode }}
              </span>
            </span>
          </span>
        </span>
        <slot name="edit-shipping-address"></slot>
      </div>
      <div 
        v-if="showBrTaxAddressTips"
        class="tax-number-tips info-item"
      >
        {{ language.SHEIN_KEY_PWA_30871 }}
      </div>
      <div 
        v-if="showKrAddressTips"
        class="kr-tips info-item"
      >
        {{ language.SHEIN_KEY_PWA_32672 }}
      </div>
      <div
        v-show="dispalyRecomAddress"
        class="shipping-address-recom"
        @click="controlDialogShow"
      >
        <sui_icon_location_15px_2 class="icon" size="15px" />
        <div class="shipping-address-recom-content">
          <span> {{ language.SHEIN_KEY_PWA_25863 }}</span>
          <sui_icon_more_right_16px_2 size="16px" :is-rotate="locals.GB_cssRight" />
        </div>
      </div>
    </div>
    <div
      v-if="!hiddenBillingAddress"
      class="info"
    >
      <div class="title"> 
        {{ language.SHEIN_KEY_PWA_14973 }} 
        <sui_icon_more_up_16px
          v-if="canShrinkBillingAddress" 
          size="16px" 
          color="#222222"
          :class="shrinkBillingAddressIconClass"
          @click.native="handleShrinkBillingAddress" 
        />
      </div>
      <div
        class="detail-shipping-flexbetween info-item"
        :class="shrinkBillingAddressClass"
      >
        <span>
          <span class="name-phone">
            <span
              v-if="orderInfo.country_id == '108'"
              class="name"
            >
              {{ orderInfo.lastname }} {{ orderInfo.firstname }}
            </span>
            <span
              v-else
              class="name"
            >
              {{ orderInfo.firstname }} {{ orderInfo.lastname }}
            </span>
            <span class="phone">{{ orderInfo.telephone }}</span>
          </span>
          <span v-if="orderInfo.country_id == '108'">
            〒 {{ orderInfo.postcode }}
          </span>
          <span v-else>
            {{ [orderInfo.address_1, orderInfo.address_2].join(' ') }}
          </span>
          <br />
          <span v-if="orderInfo.country_id == '108'">{{
            [
              orderInfo.country_name,
              orderInfo.province,
              orderInfo.city,
              orderInfo.district,
              orderInfo.address_1,
              orderInfo.address_2
            ].join(' ')
          }}</span>
          <span v-else>{{
            [
              orderInfo.district,
              orderInfo.city,
              orderInfo.province,
              orderInfo.country_name,
              orderInfo.postcode
            ].join(' ')
          }}</span>
        </span>
        <slot name="edit-billing-address"></slot>
      </div>
    </div>
    <div
      v-if="
        orderInfo.orderExtend &&
          orderInfo.orderExtend.buyCouponAmountPrice &&
          orderInfo.orderExtend.buyCouponAmountPrice.amountWithSymbol &&
          orderInfo.orderExtend.buyCouponAmountPrice.amount > 0
      "
      class="info"
    >
      <div class="title"> 
        {{ language.SHEIN_KEY_PWA_16631 }} 
      </div>
      <p>
        {{
          template(
            orderInfo.orderExtend.buyCouponAmountPrice.amountWithSymbol,
            language.SHEIN_KEY_PWA_17168
          )
        }}
      </p>
    </div>

    <slot name="footer"></slot>

    <CustomerSyncToUserAddress
      v-if="controlDialog"
      :controlDialog="controlDialog"
      :orderData="orderData"
      :language="language"
      @controlDialogClose="controlDialogClose"
      @recomAddressClose="recomAddressClose"
    />
  </div>
</template>

<script>
import schttp from 'public/src/services/schttp'
import { mapState } from 'vuex'
import { template } from '@shein/common-function'
import { sortAddressCardInfo } from 'public/src/pages/components/address/config'
import CustomerSyncToUserAddress from 'public/src/pages/components/customerSyncToUserAddress/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { sui_icon_more_right_16px_2, sui_icon_location_15px_2, sui_icon_more_up_16px, sui_icon_me_shop_15px } from '@shein-aidc/icon-vue2'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'

daEventCenter.addSubscriber({ modulecode: '2-29' })
export default {
  name: 'ShippingBillingAddressDetail',
  components: {
    CustomerSyncToUserAddress,
    sui_icon_more_right_16px_2,
    sui_icon_location_15px_2,
    sui_icon_more_up_16px,
    sui_icon_me_shop_15px
  },
  props: {
    canShrinkBillingAddress: {
      type: Boolean,
      default: false
    },
    orderInfo: {
      type: Object,
      default: () => {}
    },
    newOrderInfo: {
      type: Object,
      default: null
    },
    isNotPayVip: {
      type: Boolean,
      default: true
    },
    isUnPaiMoreAddressOrder: {
      type: Boolean,
      default: false
    },
    // jira.FR-11452
    // 现金支付方式不需要展示账单地址
    hiddenBillingAddress: {
      type: Boolean,
      default: false
    },
    customerItemSyncToUserAddressAbt: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      shrinkBillingAddress: false,
      controlDialog: false,
      showRecomAddress: true,
      secondLanguage: {
        state: '',
        city: '',
        district: ''
      },
      secondLanguageCache: null
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'language',
      'SECOND_LANGUAGE_COUNTRIES',
      'SHOP_TRANSIT',
      'orderResultAbtInfo',
      'stateCitySecondLanguage',
      'locals'
    ]),
    // * 是否展示店配icon
    isShowStoreIcon() {
      return this.isUnPaiMoreAddressOrder &&  TRANSPORT_TYPE_SHOP.includes(this.orderInfo.subsidiary.transport_type)
    },
    shrinkBillingAddressClass() {
      return this.shrinkBillingAddress ? ['detail-shipping-flexbetween', 'shrink-billing__address'] : ['detail-shipping-flexbetween']
    },
    shrinkBillingAddressIconClass() {
      return this.shrinkBillingAddress ? ['billing-address__icon', 'shrink-billing__address-icon'] : ['billing-address__icon']
    },
    orderData(){
      return this.newOrderInfo ? this.newOrderInfo : this.orderInfo
    },
    dispalyRecomAddress() {
      const flag =
        this.customerItemSyncToUserAddressAbt?.Order_Details_Switch === 'on' &&
        this.orderInfo?.notify_user === 1 &&
        this.orderInfo?.address_id &&
        this.showRecomAddress
      if (flag) {
        daEventCenter.triggerNotice({
          daId: '2-29-1',
          extraData: {
            name: 'expose_customer_sync_to_address'
          }
        })
      }
      return flag
    },
    shippingAddress() {
      const { shipping_country_id, shipping_state_id, shipping_city_id, shipping_district_id } = this.orderInfo
      return {
        shipping_country_id,
        shipping_state_id,
        shipping_city_id,
        shipping_district_id
      }
    },
    shipTransitCountry () {
      return this.SHOP_TRANSIT?.countries?.includes(+this.orderInfo?.shipping_country_id)
    },
    showBrTaxNumberNameCheck() {
      return !!this.orderResultAbtInfo?.showBrTaxNumberNameCheck
    },
    showBrTaxAddressTips(){
      return this.showBrTaxNumberNameCheck && this.orderInfo?.real_name_authentication == 2 && this.orderInfo?.shipping_country_id ==  30
    },
    showKrAddressTips() {
      return this.showBrTaxNumberNameCheck && (this.orderInfo?.real_name_authentication == 2 || this.orderInfo?.real_name_authentication == 0) && this.orderInfo?.shipping_country_id ==  197
    },
  },
  watch: {
    shippingAddress: {
      handler() {
        this.getSecondLanguage()
      },
      immediate: true
    },
    canShrinkBillingAddress: {
      handler(newValue) {
        this.shrinkBillingAddress = newValue
      },
      immediate: true
    }
  },
  methods: {
    template,
    sortAddressCardInfo,
    // * 账单地址收缩控制
    handleShrinkBillingAddress(){
      this.shrinkBillingAddress = !this.shrinkBillingAddress
    },
    /** 地址推荐入口关闭 */
    recomAddressClose() {
      this.showRecomAddress = false
      this.controlDialog = false
    },
    /** 地址推荐弹窗展示 */
    controlDialogShow() {
      this.controlDialog = true
      daEventCenter.triggerNotice({
        daId: '2-29-1',
        extraData: {
          name: 'click_customer_sync_to_address'
        }
      })
    },

    /** 地址推荐弹窗关闭 */
    controlDialogClose() {
      this.controlDialog = false
    },
    async getSecondLanguage() {
      this.secondLanguage = {
        state: '',
        city: '',
        district: ''
      }
      const {
        shipping_country_id: countryId,
        shipping_state_id: stateId,
        shipping_city_id: cityId,
        shipping_district_id: districtId
      } = this.orderInfo
      if (this.SECOND_LANGUAGE_COUNTRIES?.includes(+countryId) && (stateId || cityId || districtId)) {
        const cacheKey = `${countryId}_${stateId || '0'}_${cityId || '0'}_${districtId || '0'}`
        if (!this.secondLanguageCache) {
          this.secondLanguageCache = {
            [cacheKey]: this.stateCitySecondLanguage || {}
          }
        }
        if (this.secondLanguageCache[cacheKey]) {
          this.secondLanguage = this.secondLanguageCache[cacheKey]
        } else {
          let data = await schttp({
            url: '/api/user/addressbook/stateCitySecondLanguage/get',
            params: { countryId, stateId, cityId, districtId }
          })
          if (data.code == 0 && data.info && data.info.res) {
            const {
              stateSecondLanguage = '',
              citySecondLanguage = '',
              districtSecondLanguage = ''
            } = data.info.res
            this.secondLanguageCache[cacheKey] = {
              state: stateSecondLanguage,
              city: citySecondLanguage,
              district: districtSecondLanguage
            }
            this.secondLanguage = this.secondLanguageCache[cacheKey]
          }
        }
      }
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.detail-shipping-flexbetween {
  .flexbox();
  .space-between();
}

.order-edit-pay {
	background: #fff;
	> .info {
		.border-dpr(border-bottom,2px,#e5e5e5);
		padding: 20/75rem .32rem;
		&:last-child {
			.border-dpr(border-bottom,0,#e5e5e5);
		}
		> .title {
			color: #767676;
			.flexbox();
			.align-center();
			.space-between();
			font-weight: 400;
      font-size: 11px;
      .billing-address__icon {
        transition: all ease 0.3s;
        cursor: pointer;
      }
      .shrink-billing__address-icon {
        transform: rotate(180deg);
      }
		}
		> p,
    > .info-item {
			.font-dpr(26px);
			color: #222;
      &.mshe-flexbetween{
        min-height: 48/75rem;
      }
			> i {
				background-image: url(/pwa_dist/images/checkcard-e488f6ed3d.png);
				background-size: 2.8rem auto;
				display: block;
				.margin-r(.2rem);
			}
			>span{
				max-width: 320/345*100%;
				word-break: break-word;
			}
			.name-phone{
				display: flex;
				line-height: 38/@w2-375rem;
				font-size: 28/@w2-375rem;
				.text-overflow();
        .name{
          .margin-r(16/@w2-375rem);
          font-weight: 600;
          .text-overflow();
        }
        .phone{
          font-size: 24/@w2-375rem;
          color: #767676;
          white-space: nowrap;
        }
      }
		}
    .shrink-billing__address {
     display: none;
    }
		.payment {
			width: .8rem;
      height: .52rem;
      .margin-r(0.12rem);
		}
		.address-edit{
			i {
				color: @sui_color_gray_dark1;
			}
			[class*="iconfont"]{
				.font-dpr(36px);
			}
		}
	}
}
.shipping-address-recom {
  display: flex;
  align-items: center;
  gap: 0.106rem;
  .icon {
    color: @sui_color_link;
  }
  .shipping-address-recom-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-family: SF UI Text;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 400;
    color: @sui_color_link;
  }
}
</style>
<style lang="less" scoped>
.order-edit-pay>.info>.kr-tips {
  color: @sui_color_unusual;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.address-content {
  display: inline-flex
}
.store-icon {
  display: inline-flex;
  width: 40 / 75rem;
  height: 40 / 75rem;
  border-radius: 100%;
  background-color: #F5FCFB;
  margin-right: 8/75rem;
  justify-content: center;
  align-items: center;
}
</style>
