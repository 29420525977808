<template>
  <s-button
    class="pay-now-button"
    :type="buttonStyle"
    :class="{
      'promotion-one': orderDetailPromotionTest == 'on1',
      'promotion-two': orderDetailPromotionTest == 'on2'
    }"
    @click="onClick"
  >
    <span
      v-if="showOrderDetailPromotionTest"
      class="pay-now-button__text"
    >
      <slot></slot>
      <span
        v-if="+countDown.h || +countDown.m || +countDown.s"
        class="pay-now-button__countdown"
      >
        {{ `${countDown.h} : ${countDown.m} : ${countDown.s}` }}
      </span>
    </span>
    <template v-else>
      <slot></slot>
    </template>
    <ClientOnly>
      <div
        v-if="showOrderDetailPromotionTest"
        class="pay-now-button__wrap"
      >
        <div
          class="pay-now-button__strip"
          :class="[
            { 'is-ar': GB_cssRight },
            orderDetailPromotionTest == 'on1' ? 'is-range': 'is-line'
          ]"
        ></div>
      </div>
      <div
        v-if="orderDetailPromotionTest == 'on2' && payNowLabelList.length"
        class="pay-now-button__footer"
      >
        <PayNowHorizontal
          v-expose="{ id: '1-19-1-138', data: analysisData }"
          :language="language"
          :pay-now-label-list="payNowLabelList"
        />
      </div>
    </ClientOnly>
  </s-button>
</template>

<script setup>
import { computed } from 'vue'
import ClientOnly from 'vue-client-only'
import PayNowHorizontal from './PayNowHorizontal.vue'

const { GB_cssRight } = gbCommonInfo

const emit = defineEmits(['click'])
const props = defineProps({
  type: {
    type: Array,
    default: () => []
  },
  countDown: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  payNowLabelList: {
    type: Array,
    default: () => []
  },
  orderDetailPromotionTest: {
    type: String,
    default: ''
  },
  analysisData: {
    type: Object,
    default: () => {}
  }
})

const onClick = event => {
  emit('click', event)
}

// 订单详情开启利诱点信息实验
const showOrderDetailPromotionTest = computed(() => {
  return ['on1', 'on2'].includes(props.orderDetailPromotionTest)
})

const buttonStyle = computed(() => {
  if (showOrderDetailPromotionTest.value) {
    return ['primary', 'H56PX']
  }
  return props.type
})
</script>

<style lang="less" scoped>
// 擦亮动画
@keyframes strip-move {
  0% {
    left: -30px;
  }
  10% {
    left: 0;
  }
  45% {
    left: calc(100% + 0.2667rem);
  }
  100% {
    left: calc(100% + 0.2667rem);
  }
}

@keyframes strip-move-rtl {
  0% {
    right: -30px;
  }
  10% {
    right: 0;
  }
  45% {
    right: calc(100% + 0.2667rem);
  }
  100% {
    right: calc(100% + 0.2667rem);
  }
}

@keyframes strip-range-move {
  0% {
    left: 0;
  }
  10% {
    left: 0;
  }
  45% {
    left: calc(100% + 110px);
  }
  100% {
    left: calc(100% + 110px);
  }
}

@keyframes strip-range-move-rtl {
  0% {
    right: 0;
  }
  10% {
    right: 0;
  }
  45% {
    right: calc(100% + 110px);
  }
  100% {
    right: calc(100% + 110px);
  }
}

.pay-now-button {
  direction: ltr !important;

  &.promotion-one {
    position: relative;
    padding: 0 40/75rem;
  }

  &.promotion-two {
    overflow: initial;
  }

  &__footer {
    position: absolute;
    top: 10/75rem;
    right: -10/75rem;
    transform: translateY(-100%);
  }

  &__wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  &__text {
    position: relative;
    z-index: 5;
    font-size: 16px;
    color: @sui_color_white;
  }

  &__countdown {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
  }

  &__strip {
    content: ' ';
    display: block;
    height: calc(100% + 10px);
    position: absolute;
    top: -4px;
    left: 0;
    pointer-events: none;

    &.is-line {
      width: 6px;
      background: linear-gradient(
        95deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.1)
      );
      transform: rotate(20deg);
      animation: strip-move 2.8s infinite;

      &.is-ar {
        animation: strip-move-rtl 2.8s infinite;
      }
    }

    &.is-range {
      width: 100px;
      background: linear-gradient(
        95deg,
        rgba(255, 255, 255, 0) -1.19%,
        rgba(255, 255, 255, 0.3) 85.48%,
        rgba(255, 255, 255, 0) 85.49%
      );
      transform: skew(-30deg) translateX(-100%);
      animation: strip-range-move 2.8s infinite;

      &.is-ar {
        animation: strip-range-move-rtl 2.8s infinite;
      }
    }
  }
}
</style>
