<template>
  <div class="detail-userinfo">
    <!-- 订单地址 -->
    <!-- 新编辑按钮 -->
    <EditAddressBtnNew
      v-if="showEditOrderAddress"
      ref="editAddressBtnNew"
      :class="{'mshe-fr': showNewAddressBtn}"
      :editAddressNewConfigList="editAddressNewConfigList"
      :order="orderInfo"
      :language="language"
      :button-style="['H48PX']"
      :show-short="showNewAddressBtn"
      :newEditAddressOptions="newEditAddressOptions"
      @showEditAddressDialog="showEditAddressDialogEvt"
    >
      <i
        class="suiiconfont sui_icon_edit_20px"
      >
      </i>
    </EditAddressBtnNew>
    <template
      v-else
    >
      <div
        v-if="
          (editAddressFlag(orderInfo) == 1 &&
            orderType != 'oldorder' &&
            showPartOperatorBtn) ||
            [2, 3].includes(deliveryEditAddressCond)
        "
        class="address-edit mshe-fr"
      >
        <s-button
          v-if="showNewAddressBtn"
          :type="['H48PX']"
          DA-view-type="ordinary"
          DA-type="syncClick"
          DA-sa-name="shipping_adress_edit"
          :class="{
            'disabled':
              overFrontCondition > 0 ||
              deliveryEditAddressCond == 2 ||
              overB2B2CEditAddress ||
              disabledEditAddress ||
              !merchantOrderCanEdit
          }"
          @click="toClickEditAddress"
        >
          {{ language.SHEIN_KEY_PWA_23929 }}
        </s-button>
        <i
          v-else
          class="suiiconfont sui_icon_edit_20px"
          :class="{
            'btn-disabled':
              overFrontCondition > 0 ||
              deliveryEditAddressCond == 2 ||
              overB2B2CEditAddress ||
              disabledEditAddress ||
              !merchantOrderCanEdit
          }"
          DA-view-type="ordinary"
          DA-type="syncClick"
          DA-sa-name="shipping_adress_edit"
          @click="toClickEditAddress"
        >
        </i>
      </div>
    </template>
    <div class="du-list">
      <sui_icon_me_fill_15px size="15px" />
      <div class="name-phone">
        <span class="name">
          {{ sortAddressCardInfo({ detail: orderInfo, fromType: 'shipping' }) }}
        </span>
        <span class="phone">
          {{ orderInfo.shipping_telephone }}
        </span>
      </div>
    </div>
    <div class="du-list">
      <sui_icon_location_fill_15px size="15px" />
      <div class="du-list-hide">
        <p  
          v-if="orderInfo.shipping_country_id == '108'"
        >
          〒 {{ orderInfo.shipping_postcode }}
        </p>
        <p v-else>
          <template
            v-if="shipTransitCountry && (orderInfo.subsidiary && orderInfo.subsidiary.store_id)">
            {{ orderInfo.subsidiary.store_name }}
            <template v-if="orderInfo.shipping_country_id == '209'">
              {{
                orderInfo.subsidiary && orderInfo.subsidiary.store_type == 1
                  ? '(7-ELEVEN)'
                  : '(全家便利商店)'
              }}
            </template>
          </template>
          <template
            v-if="!(orderInfo.shipping_country_id == '209' && (orderInfo.subsidiary && orderInfo.subsidiary.store_id))"
          >
            {{ orderInfo.shipping_address_1 }}
            {{ orderInfo.shipping_address_2 }}
            <template v-if="orderInfo.shipping_country_id == '209' && orderInfo.type == '3'">
              （{{ language.SHEIN_KEY_PWA_17662 }}）
            </template>
          </template>
        </p>

        <p 
          v-if="orderInfo.shipping_country_id == '108'"
        >
          {{ orderInfo.shipping_country_name }}
          {{ orderInfo.shipping_province }}
          {{ orderInfo.shipping_city }}
          {{ orderInfo.shipping_district }}
          {{ orderInfo.shipping_street }}
          {{ orderInfo.shipping_address_1 }}
          {{ orderInfo.shipping_address_2 }}
        </p>
        <p v-else>
          <template v-if="orderInfo.shipping_country_id == '209' && (orderInfo.subsidiary && orderInfo.subsidiary.store_id)">
            {{ orderInfo.shipping_address_1 }}
            {{ orderInfo.shipping_address_2 }}
          </template>
          {{ orderInfo.shipping_street }} {{ orderInfo.shipping_district }}
          {{ orderInfo.shipping_city }} {{ orderInfo.shipping_province }}
          {{ orderInfo.shipping_country_name }}
          {{ orderInfo.shipping_postcode }}
        </p>
        <div 
          v-if="showBrTaxAddressTips"
          class="tax-number-tips"
        >
          {{ language.SHEIN_KEY_PWA_30871 }}
        </div>
        <div 
          v-if="showKrAddressTips"
          class="kr-tips"
        >
          {{ language.SHEIN_KEY_PWA_32672 }}
        </div>
      </div>
    </div>
    <div
      v-show="dispalyRecomAddress"
      class="du-list du-list-recom"
      @click="controlDialogShow"
    >
      <sui_icon_location_15px_2 class="icon" size="15px" />
      <div class="du-list-hide du-list-recom-hide">
        <span> {{ language.SHEIN_KEY_PWA_25863 }}</span>
        <sui_icon_more_right_16px_2 size="16px" :is-rotate="locals.GB_cssRight" />
      </div>
    </div>
    <CustomerSyncToUserAddress
      v-if="controlDialog"
      :controlDialog="controlDialog"
      :orderData="orderInfo"
      :language="language"
      @controlDialogClose="controlDialogClose"
      @recomAddressClose="recomAddressClose"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isFunction } from '@shein/common-function'
import orderLogic from 'public/src/pages/common/orderLogic'
import CustomerSyncToUserAddress from 'public/src/pages/components/customerSyncToUserAddress/index'
import EditAddressBtnNew from 'public/src/pages/user/child_pages/orders/detail/components/editAddressBtnNew'
import { sortAddressCardInfo } from 'public/src/pages/components/address/config'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {
  sui_icon_me_fill_15px, sui_icon_location_fill_15px,
  sui_icon_more_right_16px_2, sui_icon_location_15px_2
} from '@shein-aidc/icon-vue2'

daEventCenter.addSubscriber({ modulecode: '2-29' })
export default {
  name: 'DetailUserInfo',
  components: {
    CustomerSyncToUserAddress,
    EditAddressBtnNew,
    sui_icon_me_fill_15px,
    sui_icon_location_fill_15px,
    sui_icon_more_right_16px_2,
    sui_icon_location_15px_2
  },
  props: [
    // eslint-disable-next-line vue/require-prop-types
    'deliveryEditAddressCond',
    // eslint-disable-next-line vue/require-prop-types
    'overFrontCondition',
    // eslint-disable-next-line vue/require-prop-types
    'overB2B2CEditAddress',
    // eslint-disable-next-line vue/require-prop-types
    'editAddressFlag',
    // eslint-disable-next-line vue/require-prop-types
    'toClickEditAddress',
    // eslint-disable-next-line vue/require-prop-types
    'merchantOrderCanEdit'
  ],
  data() {
    return {
      controlDialog: false,
      showRecomAddress: true,
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'orderInfo',
      'language',
      'orderType',
      'orderResultAbtInfo',
      'editAddressNewConfigList',
      'locals',
      'showPartOperatorBtn',
      'SHOP_TRANSIT'
    ]),
    batchModifyAddressAbt () {
      return this.orderResultAbtInfo?.batchModifyAddressAbt
    },
    showEditOrderAddress () {
      return this.orderResultAbtInfo?.showEditOrderAddress
    },
    showBrTaxNumberNameCheck() {
      return this.orderResultAbtInfo?.showBrTaxNumberNameCheck
    },
    showBrTaxAddressTips(){
      return this.showBrTaxNumberNameCheck && this.orderInfo?.real_name_authentication == 2 && this.orderInfo?.shipping_country_id ==  30
    },
    showKrAddressTips() {
      return this.showBrTaxNumberNameCheck && (this.orderInfo?.real_name_authentication == 2 || this.orderInfo?.real_name_authentication == 0 ) && this.orderInfo?.shipping_country_id ==  197
    },
    // 地址按钮-以"按钮"形式展示
    showNewAddressBtn() {
      const payNowAbtInfo = this.orderResultAbtInfo?.payNowAbtInfo || {}
      const showOrderDetailPromotionTest = ['on1', 'on2'].includes(payNowAbtInfo.OrderdetailPromotionTest)
      return showOrderDetailPromotionTest && [0, 12].includes(+this.orderInfo.orderStatus)
    },
    newEditAddressOptions () {
      return {
        batchModifyAddress: this.batchModifyAddressAbt,
        pageSource: 'OrderDetail',
        btnSource: !this.showNewAddressBtn ? 'Icon' : ''
      }
    },
    dispalyRecomAddress() {
      const flag =
        this.orderResultAbtInfo?.customerItemSyncToUserAddressAbt?.Order_Details_Switch === 'on' &&
        this.orderInfo?.notify_user === 1 &&
        this.orderInfo?.address_id &&
        this.showRecomAddress
      if (flag) {
        daEventCenter.triggerNotice({
          daId: '2-29-1',
          extraData: {
            name: 'expose_customer_sync_to_address',
          },
        })
      }
      return flag
    },
    disabledEditAddress() {
      return orderLogic.disabledEditAddress(this.orderInfo)
    },
    shipTransitCountry () {
      return this.SHOP_TRANSIT?.countries?.includes(+this.orderInfo.shipping_country_id)
    },
  },
  methods: {
    sortAddressCardInfo,
    /** 地址推荐入口关闭 */
    recomAddressClose() {
      this.showRecomAddress = false
      this.controlDialog = false
    },
    /** 地址推荐弹窗展示 */
    controlDialogShow() {
      this.controlDialog = true
      daEventCenter.triggerNotice({
        daId: '2-29-1',
        extraData: {
          name: 'click_customer_sync_to_address',
        },
      })
    },

    /** 地址推荐弹窗关闭 */
    controlDialogClose() {
      this.controlDialog = false
    },
    showEditAddressDialogEvt (options = {}) {
      this.$emit('showEditAddressDialog', options)
    },
    handleEditAddressEvt() {
      if (isFunction(this.$refs.editAddressBtnNew?.handleEditAddressEvt)) {
        this.$refs.editAddressBtnNew.handleEditAddressEvt()
      }
    },
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.detail-userinfo {
  background: #fff;
  padding: .2rem .4rem;
  margin: 16/75rem 0;
  .font-dpr(26px);
  color: #333333;
  > .du-list {
    .flexbox();
    margin: .2rem 0;
    > span {
      display: inline-block;
      max-width: 100%;
      word-break: break-all;
    }
    > i {
      .margin-r(.2rem);
      font-size: 15px;
    }
    .name-phone{
      display: flex;
      .text-overflow();
              .name{
        .margin-r(16/@w2-375rem);
                  .text-overflow();
              }
              .phone{
                  white-space: nowrap;
              }
    }
    .du-list-hide{
      overflow: hidden;
      p{
        .text-overflow();
      }
    }
  }
  .address-edit {
    .mshe-btn {
      height: rem(60);
      line-height: rem(60);
      &.disabled {
        background: #fff;
        border: 1px solid #ccc;
        color: #ccc;
        pointer-events: none;
      }
    }
    .icon-pro {
      .font-dpr(32px);
      .margin-l(.2666rem);
    }
    i {
      color: @sui_color_gray_dark1;
      .font-dpr(36px);
      &.btn-disabled{
        color: #ccc;
      }
    }
    // .icon-edit {
    // 	.font-dpr(40px);
    // 	&.btn-disabled{
    // 		color: #ccc;
    // 	}
    // }
  }
  .address-edit_box {
    margin: .2rem 0;
  }
}
.du-list-recom {
  .icon {
    color: @sui_color_link;
  }
  .du-list-recom-hide {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-family: SF UI Text;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 400;
    color: @sui_color_link;
  }
}
</style>
<style lang="less" scoped>
.kr-tips {
  color: @sui_color_unusual;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
</style>

