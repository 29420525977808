import Vue from 'vue'
import { Dialog, ButtonGroup, ButtonGroupItem, Popover, Sticky, Tab, TabItem, Badge, TitleNav, TitleNavItem, Drawer, Button, LazyMount, NetworkErrorAlert } from '@shein/sui-mobile'
import ClientOnly from 'vue-client-only'
import detailGoodsInfo from './detail_goods_info.vue'
import detailTips from './components/detailTips.vue'
import detailFooter from './detail_footer.vue'
import whatsAppVerifySubscribe from 'public/src/pages/user/child_pages/orders/detail/components/whatsAppVerifySubscribe.vue'
import DetailUserInfo from './components/DetailUserInfo.vue'
import DetailPayment from './components/DetailPayment.vue'
import detailInfo from './detail_information.vue'
import OrderDetailTrackInfo from './components/OrderDetailTrackInfo.vue'
import codSplitPackage from './components/codSplitPackage.vue'
import SupportIcon from '@customerService/components/SupportIcon.vue'
import QuickShipLabel from '../common/QuickShipLabel.vue'
import AddCartSuccessDialog from '../common/add-cart/AddCartSuccessDialog.vue'
import LargeTimeLabel from 'public/src/pages/user/child_pages/orders/common/LargeTimeLabel.vue'
import OrderPayButton from './components/OrderPayButton.vue'
import googlepayDialog from 'public/src/pages/checkout/page_tpls/googlepay_dialog.vue'
import ReturnTimeTip from 'public/src/pages/user/child_pages/orders/detail/components/ReturnTimeTip.vue'
import { sui_icon_nav_back_24px, sui_icon_doubt_16px_2, sui_icon_more_right_16px_2 } from '@shein-aidc/icon-vue2'

;[Dialog, ButtonGroup, ButtonGroupItem, Popover, Sticky, Tab, TabItem, Badge, TitleNav, TitleNavItem, Drawer, Button, NetworkErrorAlert].forEach(u => { Vue.use(u) })

export default {
  LazyMount,
  ClientOnly,
  detailGoodsInfo,
  detailTips,
  detailFooter,
  whatsAppVerifySubscribe,
  DetailUserInfo,
  DetailPayment,
  detailInfo,
  OrderDetailTrackInfo,
  codSplitPackage,
  SupportIcon,
  QuickShipLabel,
  LargeTimeLabel,
  OrderPayButton,
  googlepayDialog,
  ReturnTimeTip,
  AddCartSuccessDialog,
  sui_icon_nav_back_24px,
  sui_icon_doubt_16px_2,
  sui_icon_more_right_16px_2,
  TextEllipsis: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./components/TextEllipsis.vue'),
  MoreServices: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./MoreServices.vue'),
  orderCountdown: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./order_countdown.vue'),
  refundDetail: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./refund_order_detail.vue'),
  paymentDetail: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./payment_detail.vue'),
  orderPriceDetail: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./order_price_detail.vue'),
  OrderHelp: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./OrderHelp.vue'),
  MyService: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./MyService.vue'),
  SellerInfo: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./SellerInfo.vue'),
  OrderReturnCoupon: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./components/orderReturnCoupon.vue'),
  cancelMask: () => import(/* webpackChunkName: "OrderDetail_Comp" */'../common/cancel_reason.vue'),
  modifySubscriptionPanel: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./components/modifySubscriptionPanel.vue'),
  SuccessPanel: () => import(/* webpackChunkName: "OrderDetail_Comp" */'public/src/pages/user/child_pages/orders/common/successPanel.vue'),
  payResultTips: () => import(/* webpackChunkName: "OrderDetail_Comp" */'public/src/pages/common/orderLogic/payResultTips.vue'),
  cancelItemTip: () => import(/* webpackChunkName: "OrderDetail_Comp" */'../common/cancelItemTipComp.vue'),
  returnCoupons: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./components/Returnpoupons.vue'),
  CardBinDiscountArticle: () => import(/* webpackChunkName: "CardBinDiscountArticle_OrderDetail" */'public/src/pages/components/CardBinDiscountArticle.vue'),
  ChooseBankDrawer: () => import(/* webpackChunkName: "ChooseBankDrawer_Checkout" */'public/src/pages/checkout/vue_tpls/ChooseBankDrawer.vue'),
  bankCapitecDialog: () =>import(/* webpackChunkName: "bank_capitec_dialog" */ 'public/src/pages/checkout/vue_tpls/dialog/bank_capitec_dialog.vue'),
  ShippingDayPercent: () => import(/* webpackChunkName: "OrderDetail_Comp" */'public/src/pages/checkout/vue_tpls/shipping_day_percent.vue'),
  CustomerConfirmAddress: () => import(/* webpackChunkName: "OrderDetail_Comp" */'public/src/pages/components/confirmAddress/index.vue'),
  EditAddressConfirmDialog: () => import(/* webpackChunkName: "OrderDetail_Comp" */'../common/edit-address/EditAddressConfirmDialog.vue'),
  RecommendContainer: () => import(/* webpackChunkName: "OrderDetail_Comp" */'./RecommendContainer.vue'),
  OrderMaskDialog: () => import( /* webpackChunkName: "OrderDetail_Comp" */ 'public/src/pages/user/components/OrderMaskDialog.vue'),
  UserLoading: () => import( /* webpackChunkName: "OrderDetail_Comp" */ 'public/src/pages/user/components/UserLoading.vue'),
  CommonTipsDialog: () => import( /* webpackChunkName: "OrderDetail_Comp" */ 'public/src/pages/user/child_pages/orders/common/commonTipsDialog'),
}