<template>
  <!-- 谷歌pay弹窗 -->
  <s-dialog
    :visible.sync="visibleDialog"
    :immediately-render="true"
    :show-close="true"
    :close-on-click-modal="false"
    append-to-body
    class="googlepay-dialog-container"
    @close="handleClose"
    @close-from-icon="handleManualClose"
    @close-from-mask="handleManualClose"
  >
    <template slot="title">
      <div style="padding-bottom: 10px;">
        <img
          :src="PUBLIC_CDN + '/pwa_dist/images/googlepay-17708cb2d0.png'"
          style="height: 1.5rem; margin: 0 auto 0.6rem"
        />
        <div :id="customId"></div>
      </div>
    </template>
  </s-dialog>
</template>

<script>
import { Dialog as SDialog } from '@shein/sui-mobile'
const { PUBLIC_CDN } = gbCommonInfo
export default {
  name: 'GooglepayDialog',
  components: {
    SDialog
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    customId: {
      type: String,
      default: 'google-container'
    }
  },
  data () {
    return {
      PUBLIC_CDN,
      visibleDialog: this.visible
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler (v) {
        this.visibleDialog = v
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('update:visible', false)
      this.$emit('close')
    },
    handleManualClose () {
      this.$emit('update:visible', false)
      this.$emit('manual-close')
      window.sa && sa('send', {
        activity_name: 'expose_google_pay_popup_disappear',
        activity_param: ''
      })
    }
  }
}
</script>
