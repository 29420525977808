<template>
  <div
    class="cod-split-package"
  >
    <s-alert
      key="CodSplitPackage"
      :max-rows="3"
      :load-more-txt="language.SHEIN_KEY_PWA_16133"
      @onLoadMoreClick="onLoadMoreClick"
    >
      {{ template(codSplitPackageLen, language.SHEIN_KEY_PWA_31509) }}
    </s-alert>
    <ClientOnly>
      <s-dialog
        v-if="showText"
        :show-close="false"
        :close-on-click-modal="true"
        :visible.sync="showText"
        :immediately-render="true"
        :append-to-body="true"
        class="ccc-notice-dialog"
      >
        <template slot="title"></template>
        <div
          class="notice-ctn"
          v-html="template(codSplitPackageLen, language.SHEIN_KEY_PWA_31509)"
        ></div>
        <template slot="footer">
          <s-button
            :type="['primary']"
            :width="'100%'"
            @click="showText = !showText"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script>
import Vue from 'vue'
import ClientOnly from 'vue-client-only'
import { Dialog, Button, Alert } from '@shein/sui-mobile'
import { template } from '@shein/common-function'
import { mapState } from 'vuex'

;[Dialog, Button, Alert].forEach(u => { Vue.use(u) })

export default {
  name: 'CodSplitPackage',
  components: {
    ClientOnly
  },
  data () {
    return {
      showText: false
    }
  },
  methods: {
    template,
    onLoadMoreClick () {
      this.showText = !this.showText
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'orderInfo',
      'language'
    ]),
    codSplitPackageLen () {
      return this.orderInfo?.order_package_info_list?.length || 0
    },
  }
}
</script>
<style lang="less">
.cod-split-package{
  padding: .21333333rem .32rem 0 .32rem;
  background: #fff;
  .S-alert__type-info{
    background: #f6f6f6;
    padding: 16/75rem;
  }
  .S-alert__description{
    color: #A86104;
  }
  .S-alert__view-more{
    color: #A86104;
  }
}
</style>
