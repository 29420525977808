<template>
  <div class="order-detail-track-block">
    <div
      v-tap="{
        id: '1-19-1-94',
        data: logisticsTrackAnalysis
      }"
      class="order-detail-track-card"
      @click.stop="handelTrackClick($event)"
    >
      <div
        v-if="!!trackInfo.carrier_name"
        class="order-detail-track-card__header"
      >
        <div class="order-detail-track-card__title">
          {{ language.SHEIN_KEY_PWA_24305 }}
        </div>
        <div class="order-detail-track-card__title-sub">
          <img
            v-if="!!trackInfo.logistics_pic_url"
            class="order-detail-track-card__logo"
            :src="trackInfo.logistics_pic_url"
          />
          <span class="order-detail-track-card__name">
            {{ trackInfo.carrier_name }}
          </span>
        </div>
      </div>
      <div class="order-detail-track">
        <div class="order-detail-track__main">
          <div class="order-detail-track__title">
            <span class="order-detail-track__title-status">
              {{ mallStatusData.text }}
            </span>
            <span class="order-detail-track__title-time">
              {{ handlerDate(trackInfo.timestamp) }}
              {{ handleShortTime(trackInfo.timestamp) }}
            </span>
          </div>
          <div
            class="order-detail-track__info"
            v-html="trackContent"
          >
          </div>
        </div>
        <div class="order-detail-track__right">
          <sui_icon_more_right_16px size="16px" :is-rotate="locals.GB_cssRight" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderLogic from 'public/src/pages/common/orderLogic'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { mapState } from 'vuex'
import { sui_icon_more_right_16px } from '@shein-aidc/icon-vue2'

const { langPath } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'OrderDetailTrack',
  directives: { tap, expose },
  components: {
    sui_icon_more_right_16px
  },
  props: {
    trackInfo: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    orderStatusText: {
      type: String,
      default: ''
    },
    isProcessing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'locals'
    ]),
    logisticsTrackAnalysis() {
      return {
        time: 0,
        billno: this.trackInfo.billno,
        reference_number: this.trackInfo.package_no
      }
    },
    mallStatusData() {
      const statusMap = {
        已下单: {
          text: this.language.SHEIN_KEY_PWA_16486
        },
        已发货: {
          text: this.language.SHEIN_KEY_PWA_22703
        },
        运输中: {
          text: this.language.SHEIN_KEY_PWA_15213
        },
        派送中: {
          text: this.language.SHEIN_KEY_PWA_16469
        },
        待取件: {
          text: this.language.SHEIN_KEY_PWA_24252
        },
        签收: {
          text: this.language.SHEIN_KEY_PWA_16470
        },
        default: {
          text: this.language.SHEIN_KEY_PWA_15215
        }
      }
      const mall_status = this.trackInfo.mall_status
      const mallStatusCode = this.trackInfo.mall_status_code
      if (mall_status) {
        if (
          this.orderStatusText
        && (this.isProcessing || [2, 3].includes(mallStatusCode))
        ) {
          return {
            text: this.orderStatusText
          }
        }
        return statusMap[mall_status] || statusMap.default
      }
      return statusMap.default
    },
    //轨迹内容
    trackContent() {
      if (this.trackInfo.place && this.trackInfo.details) {
        return `${this.trackInfo.place}, ${this.trackInfo.details}`
      }
      return this.trackInfo.place || this.trackInfo.details || ''
    }
  },
  methods: {
    handlerDate(date) {
      return orderLogic.orderDateFormat(date, false, this.locals)
    },
    handleShortTime(time) {
      return orderLogic.orderCompleteShortDateFormat(time, this.locals)
    },
    handelTrackClick(event) {
      let { parentElement, tagName } = event?.target || {}
      if (tagName === 'A' || parentElement?.tagName === 'A') return
      this.$router.push(
        `${langPath}/user/orders/track/${this.trackInfo.billno}`
      )
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type, selector-nested-pattern  */
.order-detail-track-block {
  background: #fff;
}

.order-detail-track-card {
  width: 702/75rem;
  margin: 0 auto;
  box-sizing: border-box;
  background: #f6f6f6;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16/75rem 20/75rem;
    min-height: 72/75rem;
    font-size: 14px;
    border-bottom: 1px solid #e5e5e5;
    box-sizing: border-box;
  }

  &__title {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 17px;
    font-family: 'SF UI Text';

    &-sub {
      display: flex;
      align-items: center;
      margin-left: 24/75rem;
      font-size: 12px;
      font-family: 'SF UI Display';
      line-height: 14px;
    }
  }

  &__logo {
    width: 60/75rem;
    max-height: 44/75rem;
    vertical-align: middle;
    margin-right: 8/75rem;
  }

  &__name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    word-break: break-all;
  }
}

.order-detail-track {
  display: flex;
  padding: 20/75rem;
  font-family: 'SF UI Text';

  &__main {
    width: 622/75rem;
    margin-right: 12/75rem;
  }

  &__title {
    font-size: 0;
    font-weight: 600;

    &-status {
      display: inline-block;
      vertical-align: bottom;
      max-width: 100%;
      margin-right: 8/75rem;
      font-size: 12px;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-time {
      display: inline-block;
      vertical-align: bottom;
      font-size: 10px;
      line-height: 12px;
      margin-top: 2px;
    }
  }

  &__info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 11px;
    line-height: 13px;
    margin-top: 8/75rem;
  }

  &__right {
    display: flex;
    align-items: center;
    color: #959595;
  }
}
</style>
