<template>
  <div class="detail-top detail-total detail-total__new">
    <div
      v-if="!isWaitingforPayment"
      class="detail-title mshe-flexbetween"
    >
      <span>
        <span>
          {{ language.SHEIN_KEY_PWA_15096 }}: {{ orderInfo.totalPrice.amountWithSymbol }} 
        </span>
        <br />
        <span v-if="orderInfo.payable && orderInfo.payable.amount > 0">
          {{ language.SHEIN_KEY_PWA_15360 }} {{ orderInfo.payable.amountWithSymbol }}
        </span>
        <span v-else-if="orderInfo.isLocalCurrency">
          {{ language.SHEIN_KEY_PWA_16040 }}: {{ orderInfo.localCodPrice.amountWithSymbol }}
        </span>
      </span>
      <em
        :class="['detail-price__detail', { 'detail-edit-payment': locals.lang == 'de' }]"
        @click="pageStatus.paymentDetailDrawer = true"
      >
        {{ language.SHEIN_KEY_PWA_15372 }}
        <sui_icon_more_right_16px size="16px" class="detail-price__icon" :is-rotate="locals.GB_cssRight" />
      </em>
    </div>
    <template v-if="isWaitingforPayment">
      <!-- hidden-billing-address 现金支付方式不需要展示账单地址 -->
      <shipping-billing-address-detail
        :order-info="orderInfo"
        :hidden-billing-address="paymentMethod.paymentType == 2"
        :customerItemSyncToUserAddressAbt="customerItemSyncToUserAddressAbt"
        :canShrinkBillingAddress="isUnPaiMoreAddressOrder"
        :newOrderInfo="newOrderInfo"
        :isUnPaiMoreAddressOrder="isUnPaiMoreAddressOrder"
      >
        <div
          slot="header"
          class="info"
        >
          <h6>{{ language.SHEIN_KEY_PWA_15145 }}</h6>
          <p class="mshe-flexbetween">
            <span class="mshe-flexbetween">
              <template v-if="bankLogo || paymentMethod.paymentLogo">
                <img
                  class="payment"
                  :src="transformImg({ img: bankLogo || paymentMethod.paymentLogo })"
                />
              </template>
              <span
                v-if="
                  paypalGaVault &&
                    paypalGaVault.valid &&
                    paypalGaVault.type == '1' &&
                    paymentMethod.paymentMethod == 'PayPal-GApaypal'
                "
              >
                {{ (ppgvAccountInfo && ppgvAccountInfo.signUpEmail) || language.SHEIN_KEY_PWA_19684 }}
              </span>
              <span
                v-else-if="bankCodeText"
              >
                {{ bankCodeText }}
              </span>
              <span
                v-else
              >
                {{ paymentMethod.paymentTitle }}
              </span>
            </span>
            <s-button
              v-if="orderInfo.payment_method != 'cod'"
              class="edit-payment"
              :type="['H48PX']"
              DA-view-type="ordinary"
              DA-type="syncClick"
              DA-sa-name="payment_method_edit"
              @click="toEditPayment()"
            >
              {{ showNewAddressBtn ? language.SHEIN_KEY_PWA_23929: language.SHEIN_KEY_PWA_16132 }}
            </s-button>
          </p>
          <preferential-copy-block
            v-if="
              paymentDisplay(paymentMethod.paymentMethod) &&
                (cardBinRandomDiscountInfo || cardBinInitDiscountInfo || paymentText)
            "
            class="mt-6"
            :preferential-text="cardBinInitDiscountInfo"
            :randomDiscountText="cardBinRandomDiscountInfo"
            :paymentText="paymentText"
          />
        </div>
        <!-- 宅店配tab栏 -->
        <div
          v-if="isUnPaiMoreAddressOrder" 
          slot="shippingMethodTabs" 
          class="shipping-methods__wrap"
        >
          <s-tab
            v-model="methodTabSelected"
            :type="'auto'"
            @change="handleTabChange"
          >
            <s-tab-item 
              v-for="(item, index) in addressList"  
              :id="index" 
              :key="index"
            >
              {{ item.shipping_method }}
            </s-tab-item>
          </s-tab>
        </div>

        <!-- 新编辑按钮 -->
        <EditAddressBtnNew
          v-if="showEditOrderAddress"
          slot="edit-shipping-address"
          :editAddressNewConfigList="editAddressNewConfigList"
          :order="newOrderInfo"
          :language="language"
          :button-style="['H48PX']"
          :show-short="showNewAddressBtn"
          :newEditAddressOptions="newEditAddressOptions"
          :newOrderInfo="newOrderInfo"
          @showEditAddressDialog="showEditAddressDialogEvt"
        >
          <i
            class="suiiconfont sui_icon_edit_20px"
          >
          </i>
        </EditAddressBtnNew>
        <template
          v-else
        >
          <div
            v-if="
              (editAddressFlag(orderInfo) == 1 && orderType != 'oldorder' && showPartOperatorBtn) ||
                [2, 3].includes(deliveryEditAddressCond)
            "
            slot="edit-shipping-address"
            class="address-edit mshe-fr"
          >
            <s-button
              v-if="showNewAddressBtn"
              :type="['H48PX']"
              DA-view-type="ordinary"
              DA-type="syncClick"
              DA-sa-name="shipping_address_edit"
              da-event-click="1-19-1-2"
              :class="{
                'disabled':
                  overFrontCondition > 0 ||
                  deliveryEditAddressCond == 2 ||
                  overB2B2CEditAddress ||
                  disabledEditAddress
              }"
              @click="toClickEditAddress"
            >
              {{ language.SHEIN_KEY_PWA_23929 }}
            </s-button>
            <i
              v-else
              class="suiiconfont sui_icon_edit_20px"
              :class="{
                'btn-disabled':
                  overFrontCondition > 0 ||
                  deliveryEditAddressCond == 2 ||
                  overB2B2CEditAddress ||
                  disabledEditAddress
              }"
              DA-view-type="ordinary"
              DA-type="syncClick"
              DA-sa-name="shipping_address_edit"
              da-event-click="1-19-1-2"
              @click="toClickEditAddress"
            >
            </i>
          </div>
        </template>
        <div
          v-if="orderInfo.isCanEditBillingAddressByUser == 1 && editBillAddressFlag"
          slot="edit-billing-address"
          class="address-edit mshe-fr"
        >
          <s-button
            v-if="showNewAddressBtn"
            :type="['H48PX']"
            DA-view-type="ordinary"
            DA-type="syncClick"
            DA-sa-name="billing_address_edit"
            da-event-click="1-19-1-2"
            @click="toClickEditBillAddress"
          >
            {{ language.SHEIN_KEY_PWA_23929 }}
          </s-button>
          <i
            v-else
            class="suiiconfont sui_icon_edit_20px"
            DA-view-type="ordinary"
            DA-type="syncClick"
            DA-sa-name="billing_address_edit"
            da-event-click="1-19-1-2"
            @click="toClickEditBillAddress"
          ></i>
        </div>
      </shipping-billing-address-detail>
    </template>
  </div>
</template>

<script>
import orderLogic from 'public/src/pages/common/orderLogic'
import { transformImg, template, getQueryString } from '@shein/common-function'
import shippingBillingAddressDetail from '../shipping_billing_address_detail.vue'
import EditAddressBtnNew from 'public/src/pages/user/child_pages/orders/detail/components/editAddressBtnNew'
import PreferentialCopyBlock from './preferentialCopyBlock.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { abtservice } from 'public/src/services/abt'
import { handleSwitchCurrency } from 'public/src/pages/checkout/utils'
import { mapState, mapMutations } from 'vuex'
import { sui_icon_more_right_16px } from '@shein-aidc/icon-vue2'
export default {
  name: 'DetailPayment',
  components: {
    EditAddressBtnNew,
    'shipping-billing-address-detail': shippingBillingAddressDetail,
    'preferential-copy-block': PreferentialCopyBlock,
    sui_icon_more_right_16px
  },
  props: [
    // eslint-disable-next-line vue/require-prop-types
    'ppgvAccountInfo',
    // eslint-disable-next-line vue/require-prop-types
    'paypalGaVault',
    // eslint-disable-next-line vue/require-prop-types
    'toEditPayment',
    // eslint-disable-next-line vue/require-prop-types
    'editAddressFlag',
    // eslint-disable-next-line vue/require-prop-types
    'deliveryEditAddressCond',
    // eslint-disable-next-line vue/require-prop-types
    'overB2B2CEditAddress',
    // eslint-disable-next-line vue/require-prop-types
    'editBillAddressFlag',
    // eslint-disable-next-line vue/require-prop-types
    'overFrontCondition',
    // eslint-disable-next-line vue/require-prop-types
    'toClickEditAddress',
    // eslint-disable-next-line vue/require-prop-types
    'toClickEditBillAddress',
    // eslint-disable-next-line vue/require-prop-types
    'isShowCardBinDiscountABT',
    // eslint-disable-next-line vue/require-prop-types
    'customerItemSyncToUserAddressAbt',
    // eslint-disable-next-line vue/require-prop-types
    'methodTabSelected',
    // eslint-disable-next-line vue/require-prop-types
    'isUnPaiMoreAddressOrder',
    // eslint-disable-next-line vue/require-prop-types
    'newOrderInfo'
  ],
  computed: {
    ...mapState('orderDetail', [
      'currencySupport',
      'orderResultAbtInfo',
      'isSSR',
      'editAddressNewConfigList',
      'locals',
      'orderInfo',
      'language',
      'showPartOperatorBtn',
      'pageStatus',
      'orderType',
      'paymentMethod'
    ]),
    ...mapState('orderDetail/bankSelect', [
      'banksConfig',
    ]),
    isWaitingforPayment () {
      return [0, 12, 13].indexOf(this.orderInfo?.orderStatus) >= 0
    },
    // * 多地址数组
    addressList () {
      return this.orderInfo.address_list || []
    },
    // 随机立减
    cardBinRandomDiscountInfo () {
      const { showBinRandomDiscount } = this.orderResultAbtInfo || {}
      const { payment_method, order_card_bin_active_info_vo_list = [] } = this.orderInfo
      const discountActiveInfo = order_card_bin_active_info_vo_list?.find(item => item?.active_info_vo?.discount_type == 3 && item?.active_info_vo?.payment_method_list?.includes(payment_method))
      const { min_price = {}, min_random_price = {}, max_random_price = {}, issuer_bank_name = '' } = discountActiveInfo?.active_info_vo || {}

      if (showBinRandomDiscount) {
        if (issuer_bank_name && Number(min_random_price?.amount) > 0 && Number(max_random_price?.amount) > 0) {
          return Number(min_price?.amount) > 0 ? template(
            min_random_price?.amountWithSymbol,
            max_random_price?.amountWithSymbol,
            min_price?.amountWithSymbol,
            issuer_bank_name,
            this.language?.SHEIN_KEY_PWA_28691
          ) : template(
            min_random_price?.amountWithSymbol,
            max_random_price?.amountWithSymbol,
            issuer_bank_name,
            this.language?.SHEIN_KEY_PWA_28692
          )
        }
      }
      return ''
    },
    // 修改订单支付方式 - 详情卡bin优惠
    cardBinInitDiscountInfo() {
      const { payment_method } = this.orderInfo

      // 当命中多个卡bin优惠，不区分命中的类型
      if (this.orderInfo?.order_card_bin_active_info_vo_list?.length > 1) {
        const lengthObj = {
          2: this.language?.SHEIN_KEY_PWA_26144,
          3: this.language?.SHEIN_KEY_PWA_26145
        }

        return template(
          ...this.orderInfo?.order_card_bin_active_info_vo_list?.map(item => item?.active_info_vo?.issuer_bank_name),
          lengthObj[this.orderInfo?.order_card_bin_active_info_vo_list?.length]
        )
      }

      const { active_info_vo = {}  } = this.orderInfo?.order_card_bin_active_info_vo_list?.[0] || this.orderInfo?.order_card_bin_active_info_vo || {}
      const visible = this.isShowCardBinDiscountABT && active_info_vo?.discount_type
      if (!visible || !active_info_vo?.payment_method_list?.includes(payment_method)) return ''
      if (active_info_vo?.discount_type === 1) {
        return template(
          active_info_vo?.full_discount_price?.amountWithSymbol,
          active_info_vo?.issuer_bank_name,
          this.language.SHEIN_KEY_PWA_24523 || ''
        )
      }
      if (active_info_vo?.discount_type === 2) {
        return template(
          active_info_vo?.max_price?.amount > 0
            ? active_info_vo?.max_price.amountWithSymbol
            : active_info_vo?.percent || '',
          active_info_vo?.issuer_bank_name,
          active_info_vo?.max_price?.amount > 0
            ? this.language.SHEIN_KEY_PWA_24525
            : this.language.SHEIN_KEY_PWA_24454 || ''
        )
      }
      return ''
    },
    // 随机立减优惠
    paymentText() {
      let { paymentMethod: paymentMethodCode } = this.paymentMethod || {}
      const onlinePayDiscountInfo =
        (this.orderInfo && this.orderInfo?.onlinePayDiscountInfo) ||
        this.orderInfo?.onlinePayDiscountValue?.[paymentMethodCode] ||
        {}
      const minValue = onlinePayDiscountInfo?.minValue || onlinePayDiscountInfo?.min
      const onlineText = +minValue?.amount
        ? template(
          onlinePayDiscountInfo?.randomMin?.amountWithSymbol,
          onlinePayDiscountInfo?.randomMax?.amountWithSymbol,
          minValue.amountWithSymbol,
          this.language.SHEIN_KEY_PWA_24937
        ) || ''
        : template(
          onlinePayDiscountInfo?.randomMin?.amountWithSymbol,
          onlinePayDiscountInfo?.randomMax?.amountWithSymbol,
          this.language.SHEIN_KEY_PWA_24866
        ) || ''
      return (onlinePayDiscountInfo && onlinePayDiscountInfo.discountType == 2 && onlineText) || ''
    },
    bankCodeText() {
      return this.banksConfig[this.paymentMethod.paymentMethod]?.bankCodeText || ''
    },
    bankLogo() {
      return this.banksConfig[this.paymentMethod.paymentMethod]?.bankLogo || ''
    },
    disabledEditAddress() {
      return orderLogic.disabledEditAddress(this.orderInfo)
    },
    batchModifyAddressAbt () {
      return this.orderResultAbtInfo?.batchModifyAddressAbt
    },
    showEditOrderAddress () {
      return this.orderResultAbtInfo?.showEditOrderAddress
    },
    // 地址按钮-以"按钮"形式展示
    showNewAddressBtn() {
      const payNowAbtInfo = this.orderResultAbtInfo?.payNowAbtInfo || {}
      const showOrderDetailPromotionTest = ['on1', 'on2'].includes(payNowAbtInfo.OrderdetailPromotionTest)
      return showOrderDetailPromotionTest && [0, 12].includes(+this.orderInfo.orderStatus)
    },
    newEditAddressOptions () {
      return {
        batchModifyAddress: this.batchModifyAddressAbt,
        pageSource: 'OrderDetail',
        btnSource: !this.showNewAddressBtn ? 'Icon' : ''
      }
    },
  },
  mounted() {
    this.anglesTrigger()
    this.initSpecialPaymentEvt()
  },
  methods: {
    transformImg,
    getQueryString,
    ...mapMutations('orderDetail', ['assignState']),
    handleTabChange(data){
      this.$emit('tabChange', data)
    },
    paymentDisplay: function (code) {
      /**
       * 获取强制切换币种
       */
      if (this.orderInfo && this.orderInfo?.orderCurrency) {
        const { isChangeCurrency } = handleSwitchCurrency({
          currencySupport: this.currencySupport,
          currency: this.orderInfo?.orderCurrency?.code,
          countryCode: this.orderInfo?.countryCode,
          paymentCode: code
        })
        return !isChangeCurrency
      } else {
        return false
      }
    },
    // 获取随机立减abt
    async randomGetAbt() {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const { PaymentRandomDiscount } = await abtservice.getUserAbtResult({
        newPosKeys: 'PaymentRandomDiscount'
      })
      return PaymentRandomDiscount?.param?.PaymentRandomShow == 'Show'
    },
    // 支付方式全量上报
    async anglesTrigger() {
      // const randomDiscountAbt = await this.randomGetAbt()
      let { paymentMethod: paymentMethodCode } = this.paymentMethod || {}
      const onlinePayDiscountInfo =
        (this.orderInfo && this.orderInfo?.onlinePayDiscountInfo) ||
        this.orderInfo?.onlinePayDiscountValue?.[paymentMethodCode] ||
        {}
      // 符合随机立减 不受abt影响 expose_randomdiscount_abt
      daEventCenter.triggerNotice({
        daId: '1-11-1-144',
        extraData: {
          is_full: onlinePayDiscountInfo.discountType == 2 ? 1 : 0,
          payment_list: paymentMethodCode
        }
      })
      // 随机立减文案，受abt影响 expose_randomdiscount
      // if(randomDiscountAbt){
      //   daEventCenter.triggerNotice({ daId: '1-11-1-145', extraData: {
      //     payment_list: paymentMethodCode
      //   } })
      // }
    },
    initSpecialPaymentEvt() {
      this.$nextTick(() => {
        // 支付转化带参数[show_error_guide_payment == 1]进入订单详情页, 拉起支付方式供用户直接点击支付
        let showErrorGuidePayment = this.getQueryString({ key: 'show_error_guide_payment' })
        let isUnPaidOrder = [0, 12, 13]?.includes(+this.orderInfo?.orderStatus)
        let isUnCodeOrder = this.orderInfo?.payment_method != 'cod'
        if (isUnCodeOrder && isUnPaidOrder && showErrorGuidePayment == 1) {
          this.assignState({ unPaidOrderLoadPayment: true })
          this.toEditPayment()
        }
      })
    },
    showEditAddressDialogEvt (options = {}) {
      this.$emit('showEditAddressDialog', options)
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.detail-total__new{
  background: #fff !important; 
  .detail-title {
    padding: .26666667rem .32rem !important; 
    height: auto !important;
    align-items: center;
  }
  .detail-price__icon{
    .margin-l(0.1rem) !important;
    color: #222 !important;
  }
  .detail-price__detail{
    display: flex;
    align-items: center;
    color: #222 !important;
    font-style: normal;
    .font-dpr(24px);
  }
}
</style>
<style lang="less" scoped>
.shipping-methods {
  &__wrap {
    width: 100%;
    border-top: 16 / 75rem solid #F6F6F6;
  }
}
</style>


