<template>
  <div v-if="showAlert">
    <s-alert
      v-if="showAlert"
      :key="key"
      :type="type"
      :closable="closable"
      :show-icon="showIcon"
      :rtl-icon-roate="rtlIconRoate"
    >
      {{ message }}
    </s-alert>
  </div>
</template>

<script>
export default {
  name: 'AlertTip',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'info'
    },
    message: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    rtlIconRoate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showAlert: false
    }
  },
  computed: {
    key() {
      let timer = new Date().getTime()
      return `${timer}_alert`
    }
  },
  watch: {
    show(val) {
      this.showAlert = false
      this.$nextTick(() => {
        this.showAlert = !!val
      })
    }
  },
  mounted() {
    this.showAlert = !!this.show
  },
}
</script>
