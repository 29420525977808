<template>
  <div class="subscribe-wrap">
    <div class="subscribe-wrap__title">
      <sui_icon_whatsapp_15px size="15px" />
      <span class="title">{{ language.SHEIN_KEY_PWA_17818 }}</span>
    </div>
    <div class="subscribe-wrap__content">
      <div
        v-if="!isSubscribed"
        class="subscribe"
      >
        <div class="subscribe__content">
          <p v-html="labelText"></p>
        </div>
        <div class="subscribe__footer">
          <s-button
            :type="['primary', 'H48PX']"
            :loading="loading"
            @click="bindChannel"
          >
            {{ language.SHEIN_KEY_PWA_23006 }}
          </s-button>
        </div>
      </div>
      <div
        v-if="isSubscribed"
        class="unsubscribe"
      >
        <span
          class="unsubscribe__content"
          v-html="unsubscribeText"
        ></span>
        <div class="unsubscribe__footer">
          <s-button
            :type="['primary', 'H48PX']"
            @click="editChannel"
          >
            {{ language.SHEIN_KEY_PWA_23014 }}
          </s-button>
        </div>
      </div>
    </div>


    <ClientOnly>
      <!-- 绑定渠道抽屉 -->
      <bind-drawer
        v-if="bindDrawerPop"
        ref="bindDrawer"
        :bindDrawerPop="bindDrawerPop"
        :language="language"
        @submitBind="submitBind"
        @closeBind="closeBind"
      />

      <!-- edit抽屉 -->
      <handler-drawer
        ref="handlerDrawer"
        :show-pop="isShowHandlerPop"
        :language="language"
        @handleSelect="handleSelectItem"
      />

      <!-- unsubscribe dialog -->
      <LazyMount>
        <s-dialog
          class="unsubscribe-dialog"
          :visible.sync="unsubscribeDialogShow"
          :show-close="true"
        >
          <template slot="title">
            {{ language.SHEIN_KEY_PWA_23030 }}
          </template>
          <div class="unsubscribe-dialog__content">
            {{ language.SHEIN_KEY_PWA_23031 }}
          </div>
          <template slot="footer">
            <s-button-group hor>
              <s-button-group-item
                :type="['H72PX']"
                @click="handleUnsubscribe(false)"
              >
                {{ language.SHEIN_KEY_PWA_18827 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['primary', 'H72PX']"
                @click="handleUnsubscribe(true)"
              >
                {{ language.SHEIN_KEY_PWA_18826 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </LazyMount>
    </ClientOnly>
  </div>
</template>
  
<script>
import Vue from 'vue'
import ClientOnly from 'vue-client-only'
import { mapState, mapMutations } from 'vuex'
import { Button, Dialog, LazyMount } from '@shein/sui-mobile'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import schttp from 'public/src/services/schttp'
import { sui_icon_whatsapp_15px } from '@shein-aidc/icon-vue2'

;[Dialog, Button].forEach(u => { Vue.use(u) })
export default {
  name: 'WhatsAppVerifySubscribe',
  components: {
    ClientOnly,
    LazyMount,
    sui_icon_whatsapp_15px,
    BindDrawer: () => import(/* webpackChunkName: "OrderDetailSubscribe_Comp" */'public/src/pages/user/child_pages/orders/detail/components/bindDrawer.vue'),
    HandlerDrawer: () => import(/* webpackChunkName: "OrderDetailSubscribe_Comp" */'public/src/pages/user/child_pages/orders/detail/components/handlerDrawer.vue')
  },
  props: {
    language: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  data() {
    return {
      isShowHandlerPop: true,
      unsubscribeDialogShow: false,
      loading: false,
      bindDrawerPop: false
    }
  },
  computed: {
    ...mapState('orderDetail', ['isSubscribed', 'subscribe_value', 'locals']),
    labelText() {
      return (
        this.language?.SHEIN_KEY_PWA_23004?.replace(
          '{0}',
          `<a href="${this.linkHref}" target="_blank" style='color: #2D68A8; text-decoration: none'>${this.language?.SHEIN_KEY_PWA_23005}</a>`
        ) || ''
      )
    },
    linkHref() {
      if (this.locals.WEB_CLIENT == 'shein') {
        return `${this.locals.langPath}/Privacy-Security-Policy-a-282.html`
      }
      return `${this.locals.langPath}/Privacy-Security-Policy-a-488.html`
    },
    unsubscribeText() {
      return template(
        `<span style='font-weight: 700;'>${this.subscribe_value}</span>`,
        this.language.SHEIN_KEY_PWA_24906
      )
    },
  },
  watch: {
    isSubscribed(val) {
      if (val) {
        daEventCenter.triggerNotice({
          daId: '1-19-1-1007',
          extraData: {
            subscribe_type: 1,
          },
        })
      } else {
        daEventCenter.triggerNotice({
          daId: '1-19-1-1001',
          extraData: {
            subscribe_type: 1,
          },
        })
      }
    },
  },
  methods: {
    ...mapMutations('orderDetail', ['assignState']),
    async getWhatsAppSubStatus() {
      schttp({
        url: '/api/user/contact/getSubList/get',
        method: 'POST',
        data: {
          subscribe_type: '3',
        },
      }).then((res) => {
        if (res.code == 0) {
          const result = res?.info?.result[0] || {}
          if (result.subscribe_status == 2) {
            this.assignState({
              subscribe_value: result.subscribe_value || '',
              isSubscribed: true
            })
          } else {
            this.assignState({
              subscribe_value: '',
              isSubscribed: false
            })
          }
        }
      })
    },
    handleSelectItem(value) {
      if (value == 0) {
        this.bindDrawerPop = true
      } else {
        this.unsubscribeDialogShow = true
      }
      daEventCenter.triggerNotice({
        daId: '1-19-1-1008',
        extraData: {
          subscribe_type: 1,
          act_tp: value ? 'unsubscribe' : 'change_account',
        },
      })
    },
    handleUnsubscribe(value) {
      if (value) {
        const params = {
          subscribe_type: 3,
          subscribe_status: 2,
          scene_type: 12,
          bind_reward: 0,
        }
        schttp({
          method: 'POST',
          url: `/api/user/privacy_request/editSubStatus/update`,
          data: params,
        }).then((res) => {
          if (res && res.code == 0) {
            this.$toast(this.language.SHEIN_KEY_PWA_24910)
            this.unsubscribeDialogShow = false
            this.getWhatsAppSubStatus()
          } else {
            this.$toast(res.tips || this.language.SHEIN_KEY_PWA_24910)
          }
          daEventCenter.triggerNotice({
            daId: '1-19-1-1009',
            extraData: {
              subscribe_type: 1,
              result: res && res.code == 0 ? 0 : 1,
            },
          })
        })
      } else {
        this.unsubscribeDialogShow = false
        daEventCenter.triggerNotice({
          daId: '1-19-1-1010',
          extraData: {
            subscribe_type: 1,
          },
        })
      }
    },
    bindChannel() {
      this.loading = true
      this.bindDrawerPop = true
      daEventCenter.triggerNotice({
        daId: '1-19-1-1002',
        extraData: {
          subscribe_type: 1,
        },
      })
    },
    editChannel() {
      this.$refs.handlerDrawer.showPop = true
      daEventCenter.triggerNotice({
        daId: '1-19-1-1006',
        extraData: {
          subscribe_type: 1,
        },
      })
      daEventCenter.triggerNotice({
        daId: '1-19-1-1007',
        extraData: {
          subscribe_type: 1,
        },
      })
    },
    submitBind() {
      this.getWhatsAppSubStatus()
    },
    closeBind(type){
      this.loading = false
      if (type == 2) {
        this.bindDrawerPop = false
      }
    },
    template
  }
}
</script>
<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.subscribe-wrap {
  padding: 12/37.5rem;
  background: #ffffff;
  margin-bottom: 16/75rem;
  &__title {
    color: #222;
    font-size: 12/37.5rem;
    font-weight: 700;
    .icon-whatsapp {
      font-size: 14/37.5rem;
    }
    .title {
      font-family: "SF UI Display";
      margin-left: 4/37.5rem;
    }
  }
  &__content {
    font-size: 12/37.5rem;
    .subscribe {
      &__content {
        padding: 8/37.5rem 0;
        color: #222;
        font-size: 12/37.5rem;
        font-family: "SF UI Text";
      }
      &__footer {
        display: flex;
        justify-content: flex-end;
        .S-button {
          &__loading{
            /deep/ .S-loading__inner-pull-up{
              width:.4267rem;
              height:.4267rem;
              margin: .08rem auto;
            }
          } 
        }
      }
    }
    .unsubscribe {
      &__content {
        padding: 8/37.5rem 0;
        color: #222;
        font-size: 12/37.5rem;
        font-family: "SF UI Text";
      }
      &__footer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .unsubscribe-dialog {
    &__content {
      margin-top: 12/37.5rem;
      color: #666;
      text-align: center;
      font-size: 14px;
      font-family: "Arial";
    }
  }
}
</style>
  
