<template>
  <div
    class="pay-now-horizontal"
    :class="classData"
  >
    <div class="pay-now-horizontal__content">
      <transition
        @enter="onEnter"
        @after-enter="onAfterEnter"
        @before-leave="onBeforeLeave"
        @enter-cancelled="onCancelled"
        @after-leave="onAfterLeave"
      >
        <div
          :key="currentIndex"
          ref="payNowLabel"
          class="pay-now-label"
          :class="classData"
          @transitionend="onTransitionend"
        >
          <Icon
            :name="item.name"
            size="14px"
            color="#fff"
            class="pay-now-label__icon"
          />
          <span class="pay-now-label__text">
            {{ item.text }}
          </span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

const { GB_cssRight } = gbCommonInfo

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  payNowLabelList: {
    type: Array,
    default: () => []
  }
})

const timer = ref(null)
const width = ref(0)
const payNowLabel = ref()
const currentIndex = ref(0)

const item = computed(() => {
  return props.payNowLabelList[currentIndex.value] || {}
})

const classData = computed(() => {
  const { type } = item.value
  return {
    'is-discount': type === 'discount',
    'is-success': type === 'success',
    'is-credit': type === 'credit',
  }
})

const initAnimation = () => {
  clearInterval(timer.value)
  timer.value = setInterval(() => {
    currentIndex.value++
    if (currentIndex.value >= props.payNowLabelList.length) {
      currentIndex.value = 0
    }
  }, 2800)
}

const getPayNowLabelWidth = () => {
  let el = payNowLabel.value
  if (Array.isArray(el)) {
    el = payNowLabel.value[0]
  }
  return (width.value = el?.offsetWidth || 0)
}

const removeProperty = el => {
  if (el) {
    ['transition', 'min-width', 'transform', 'display'].forEach(key => {
      el.style.removeProperty(key)
    })
  }
}

const onEnter = el => {
  const currentWidth = el.offsetWidth
  if (currentWidth > width.value) {
    el.style.minWidth = `${currentWidth}px`
    el.style.transform = `translateX(${GB_cssRight ? '-' : ''}${currentWidth - width.value}px)`
  } else {
    el.style.minWidth = `${width.value}px`
  }
  width.value = currentWidth
}

const onAfterEnter = el => {
  el.style.transition = 'all 0.3s'
  el.style.minWidth = `${width.value}px`
  el.style.transform = 'none'
}

const onCancelled = el => {
  removeProperty(el)
}

const onBeforeLeave = el => {
  el.style.display = `none`
}

const onTransitionend = e => {
  removeProperty(e.target)
}

const onAfterLeave = el => {
  removeProperty(el)
}

onMounted(() => {
  if (props.payNowLabelList.length > 1) {
    initAnimation()
    getPayNowLabelWidth()
  }
})

onBeforeUnmount(() => {
  clearInterval(timer.value)
  timer.value = null
})
</script>

<style lang="less" scoped>
.pay-now-horizontal {
  position: relative;
  direction: ltr !important;
  text-align: center;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    transform: translateY(100%);
    border: 5/75rem solid transparent;
  }

  &.is-discount {
    &::after {
      border-left-color: @sui_color_discount;
      border-top-color: @sui_color_discount;
    }
  }

  &.is-success {
    &::after {
      border-left-color: @sui_color_success;
      border-top-color: @sui_color_success;
    }
  }

  &.is-credit {
    &::after {
      border-left-color: #873C00;
      border-top-color: #873C00;
    }
  }

  &__content {
    overflow: hidden;
  }
}

.pay-now-label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 40/75rem;
  padding: 0 24/75rem;
  white-space: nowrap;
  line-height: normal;

  &.is-discount {
    background-color: @sui_color_discount;
  }

  &.is-success {
    background-color: @sui_color_success;
  }

  &.is-credit {
    background-color:#873C00;
  }

  &__icon {
    margin-right: 8/75rem;
  }

  &__text {
    font-size: 12px;
    font-weight: 700;
    color: @sui_color_white;
  }
}
</style>
