<template>
  <div
    class="order-pay-btn"
  >
    <!-- pay_now,不可支付,可支付去掉类disabld -->
    <template
      v-if="(orderInfo.orderStatus == 0 || orderInfo.orderStatus == 12) && (!paypalBtnShow || !paypalBnplBtnShow)"
    >
      <s-button
        v-if="orderInfo.payment_type == 2 && (orderInfo.pay_code_url || orderInfo.pay_url) "
        v-expose="{ 
          id: '1-19-1-139', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: language.SHEIN_KEY_PWA_15451,
          } 
        }"
        v-tap="{ 
          id: '1-19-1-140', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: language.SHEIN_KEY_PWA_15451,
          } 
        }"
        :type="['H56PX', 'primary']"
        class="order-detail-footer__btn"
        @click.stop="payNowCommonEvt({ payType: 1 })"
      >
        {{ language.SHEIN_KEY_PWA_15451 }}
      </s-button>
      <s-button
        v-else-if="
          paypalVenmoBtnShow &&
            ['PayPal-Venmo'].includes(orderInfo.payment_method)
        "
        v-expose="{ 
          id: '1-19-1-139', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: '',
          } 
        }"
        v-tap="{ 
          id: '1-19-1-140', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: '',
          } 
        }"
        :type="['H56PX', 'primary']"
        class="footer-venmo-btn"
        DA-view-type="ordinary"
        DA-type="syncClick"
        @click.stop="payNowCommonEvt({ payType: 2, confirmPayType: 'PayNow' })"
      >
        <img
          :src="locals.IMG_LINK['blue-venmo-button']"
          class="venmo-btn-img"
        />
      </s-button>

      <template v-else>
        <ApplePayBtn
          v-if="applepayDropoffOptimizationAbt && applepayDropoffOptimizationApollo"
          :props-style="{
            width: 'auto',
            height: '28px',
            justifyContent: 'flex-start'
          }"
          @handleBtnClick="payNowCommonEvt({ payType: 2, confirmPayType: 'PayNow' })"
        />
        <s-button
          v-else
          v-expose="{ 
            id: '1-19-1-139', 
            data: {
              order_no: orderInfo.billno,
              pay_button_text: payNowText,
            } 
          }"
          v-tap="{ 
            id: '1-19-1-140', 
            data: {
              order_no: orderInfo.billno,
              pay_button_text: payNowText,
            } 
          }"
          :type="['H56PX', 'primary']"
          DA-view-type="ordinary"
          DA-type="syncClick"
          @click.stop="payNowCommonEvt({ payType: 2, confirmPayType: 'PayNow' })"
        >
          {{ payNowText }}
        </s-button>
      </template>
    </template>

    <!-- verify,in站不能在ar站短信验证 -->
    <template v-if="orderInfo.orderStatus == 13">
      <s-button
        v-if="getCodOrderFrom(locals.lang).indexOf(orderInfo.site_from) > -1"
        v-expose="{ 
          id: '1-19-1-139', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: language.SHEIN_KEY_PWA_30858,
          } 
        }"
        v-tap="{ 
          id: '1-19-1-140', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: language.SHEIN_KEY_PWA_30858,
          } 
        }"
        DA-view-type="ordinary"
        DA-type="syncClick"
        :type="['H56PX', 'primary']"
        class="order-detail-footer__btn"
        @click.stop="payNowCommonEvt({ payType: 2, confirmPayType: 'VerifyNow' })"
      >
        {{ language.SHEIN_KEY_PWA_30858 }}
      </s-button>
      <s-button
        v-else
        v-expose="{ 
          id: '1-19-1-139', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: language.SHEIN_KEY_PWA_30858,
          } 
        }"
        v-tap="{ 
          id: '1-19-1-140', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: language.SHEIN_KEY_PWA_30858,
          } 
        }"
        class="order-detail-footer__btn"
        disabled
        :type="['H56PX', 'primary']"
      >
        {{ language.SHEIN_KEY_PWA_30858 }}
      </s-button>
    </template>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { template } from '@shein/common-function'
import applePayConfig from 'public/src/pages/common/apple_pay/index.js'

export default {
  name: 'PayButton',
  components: {
    ApplePayBtn: () => import(/* webpackChunkName: "OrderDetail_ApplePayBtn" */'public/src/pages/components/ApplePayBtn.vue'),
  },
  props: {
    paypalBtnShow: Boolean,
    paypalVenmoBtnShow: Boolean,
    paypalBnplBtnShow: Boolean,
  },
  computed: {
    ...mapState('orderDetail', [
      'language',
      'orderResultAbtInfo',
      'apolloInfo',
      'locals',
      'orderInfo'
    ]),
    ...mapGetters('orderDetail', ['getCodOrderFrom']),
    // 二次支付氛围提升探索abt
    payNowAbtInfo() {
      return this.orderResultAbtInfo?.payNowAbtInfo || {}
    },
    applepayDropoffOptimizationAbt () {
      return this.orderResultAbtInfo?.showApplepayDropoffOptimization
    },
    applepayDropoffOptimizationApollo () {
      const applePayMethods = this.apolloInfo?.APPLE_PAY_CODE_LIST?.length ? this.apolloInfo.APPLE_PAY_CODE_LIST : applePayConfig?.applePayChannel || []
      return applePayMethods?.includes?.(this.orderInfo?.payment_method)
    },
    payNowText() {
      if (this.payNowAbtInfo.OrderdetailButtonTest === 'on' && this.orderInfo.totalPrice?.amountWithSymbol) {
        return template(this.orderInfo.totalPrice.amountWithSymbol, this.language.SHEIN_KEY_PWA_30861)
      }
      return this.language.SHEIN_KEY_PWA_15655
    },
  },
  methods: {
    payNowCommonEvt(data) {
      this.$emit('pay-now-common-evt', data)
    },
  }
}
</script>
<style lang="less">
.order-pay-btn {
  margin-top: 16/75rem;

  .S-button {
    min-width: 300/75rem;
  }
}
.apple-pay-btn{
  display: inline-block;
  height: 60/75rem;
  min-width: 150px;
  button{
    border-radius: 0;
  }
}
</style>
