var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('s-button',{staticClass:"pay-now-button",class:{
    'promotion-one': _vm.orderDetailPromotionTest == 'on1',
    'promotion-two': _vm.orderDetailPromotionTest == 'on2'
  },attrs:{"type":_setup.buttonStyle},on:{"click":_setup.onClick}},[(_setup.showOrderDetailPromotionTest)?_c('span',{staticClass:"pay-now-button__text"},[_vm._t("default"),_vm._v(" "),(+_vm.countDown.h || +_vm.countDown.m || +_vm.countDown.s)?_c('span',{staticClass:"pay-now-button__countdown"},[_vm._v("\n      "+_vm._s(`${_vm.countDown.h} : ${_vm.countDown.m} : ${_vm.countDown.s}`)+"\n    ")]):_vm._e()],2):[_vm._t("default")],_vm._v(" "),_c(_setup.ClientOnly,[(_setup.showOrderDetailPromotionTest)?_c('div',{staticClass:"pay-now-button__wrap"},[_c('div',{staticClass:"pay-now-button__strip",class:[
          { 'is-ar': _setup.GB_cssRight },
          _vm.orderDetailPromotionTest == 'on1' ? 'is-range': 'is-line'
        ]})]):_vm._e(),_vm._v(" "),(_vm.orderDetailPromotionTest == 'on2' && _vm.payNowLabelList.length)?_c('div',{staticClass:"pay-now-button__footer"},[_c(_setup.PayNowHorizontal,{directives:[{name:"expose",rawName:"v-expose",value:({ id: '1-19-1-138', data: _vm.analysisData }),expression:"{ id: '1-19-1-138', data: analysisData }"}],attrs:{"language":_vm.language,"pay-now-label-list":_vm.payNowLabelList}})],1):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }