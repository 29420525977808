<template>
  <div class="detail-info j-detail-info">
    <div class="detail-info__head">
      <h5
        class="detail-info__title"
        :class="{ font: locals.IS_RW }"
      >
        {{ language.SHEIN_KEY_PWA_20019 }}
      </h5>
      <span
        v-if="orderInfo.marketing_type == 7"
        class="brithday-gift"
      >
        <img
          class="brithday-gift_tag"
          :src="`${locals.PUBLIC_CDN}/pwa_dist/images/sheinvip2/birthday_gift-9e9986fff2.png`"
        />{{ language.SHEIN_KEY_PWA_17349 }}
      </span>
      <span
        v-if="orderInfo.remark_user == 'free trial order' || orderInfo.marketing_type == 1"
        class="free-trial-tag"
      >
        {{ language.SHEIN_KEY_PWA_16212.toLowerCase() }}
      </span>
      <span
        v-if="orderInfo.marketing_type == 8"
        class="free-gift-tag"
      >
        {{ language.SHEIN_KEY_PWA_23666 }}
      </span>
    </div>
    <ul class="detail-info__wrap">
      <li class="detail-info__item j-detail-billno">
        <div class="detail-info__item-title">
          {{ language.SHEIN_KEY_PWA_20020 }}
        </div>
        <div class="detail-info__item-ctn">
          <span>{{ orderInfo.billno }}</span>
          <s-button
            class="copy"
            :type="['H48PX']"
            DA-type="syncClick"
            DA-sa-name="copy"
            :data-clipboard-text="orderInfo.billno"
            @click="copyOrderNumber"
          >
            {{ language.SHEIN_KEY_PWA_15237 }}
          </s-button>
        </div>
      </li>
      <li class="detail-info__item">
        <div class="detail-info__item-title">
          {{ language.SHEIN_KEY_PWA_20021 }}:
        </div>
        <div class="detail-info__item-ctn">
          <span>{{ handlerDate(orderInfo._addTime) }}</span>
        </div>
      </li>
      <li
        v-if="showOrderTransportTime"
        class="detail-info__item"
      >
        <div class="detail-info__item-title">
          {{ language.SHEIN_KEY_PWA_15371 }}:
        </div>
        <div class="detail-info__item-ctn">
          <span>{{ getShippingMethod }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Clipboard from 'clipboard'
import orderLogic from 'public/src/pages/common/orderLogic'
// import { formatQuickShipLabel } from 'public/src/pages/user/child_pages/orders/common/utils'

export default {
  name: 'DetailInformation',
  props: {
    showOrderTransportTime: {
      type: Boolean
    },
  },
  computed: {
    ...mapState('orderDetail', [
      'orderInfo',
      'language',
      'noQuickshipCountry',
      'orderResultAbtInfo',
      'apolloInfo',
      'locals'
    ]),
    quickShipAbt() {
      return this.orderResultAbtInfo?.quickShipAbt || {}
    },
    // * 未支付多地址场景
    isUnPaiMoreAddressOrder(){
      return [0, 12, 13]?.includes(+this.orderInfo?.orderStatus) && this.orderInfo?.address_list?.length > 1
    },
    // * 运输方式文案
    shippingMethodText(){
      const { shipping_method = '', address_list = [] } = this.orderInfo || {}
      if(this.isUnPaiMoreAddressOrder) {
        const shippingMethodList = address_list?.map(item => item?.shipping_method)
        return shippingMethodList.join(',')
      }
      return shipping_method || ''
    },
    getShippingMethod() {
      let { _allOrderGoodsList, is_multi_mall, shipping_country_code } =
        this.orderInfo || {}
      if (is_multi_mall == 1) return this.shippingMethodText || ''
      if (this.isShowQuickShipTag?.includes('conceal_QuickShipping')) return this.shippingMethodText || ''
      let res = orderLogic?.getQuickShipTransportTimeType(_allOrderGoodsList)
      const isUseQuickshipName = !this.noQuickshipCountry.includes(
        shipping_country_code.toUpperCase()
      )
      if (res == 2 && isUseQuickshipName) {
        return this.language.SHEIN_KEY_PWA_22276
        // const mallList = this.orderInfo?.mall_list || []
        // return formatQuickShipLabel({
        //   language: this.language, 
        //   quickShipAbt: this.quickShipAbt, 
        //   mallInfo: mallList[0] || {}
        // })
      }
      return this.shippingMethodText || ''
    }
  },
  methods: {
    copyOrderNumber () {
      if (typeof Clipboard !== 'undefined') {
        const clipboard = new Clipboard('.copy')

        clipboard.on('success', e => {
          console.info('Text:', e.text)
          this.$toast(this.language.SHEIN_KEY_PWA_16286)
          e.clearSelection()
        })
      }
    },
    handlerDate(date) {
      return orderLogic.orderDateFormat(date, true, this.locals)
    }
  }
}
</script>

<style lang="less" scoped>
.detail-info {
  background: #fff;
  margin: 16/75rem 0;
  padding: 20/75rem 24/75rem;
  &__head {
    display: flex;
    align-items: center;
  }
  &__title {
    .font-dpr(28px);
    color: #333;
  }
  &__wrap {
    margin: 20/75rem 0 0;
  }
  &__item {
    margin-bottom: 20/75rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item-title {
    .font-dpr(24px);
    color: #767676;
  }
  &__item-ctn {
    .font-dpr(28px);
    color: #333;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .brithday-gift {
    .font-dpr(20px);
    color: #bf9f53;
    display: flex;
    align-items: center;
    .margin-l(8/75rem);
    img {
      width: 20/75rem;
      height: auto;
      .margin-r(4/75rem);
    }
  }
  .free-trial-tag {
    .font-dpr(20px);
    color: #c44a01;
    padding: 0.02666rem 0.05333rem;
    background: #fff6f3;
    text-transform: capitalize;
    .margin-l(8/75rem);
  }
  .free-gift-tag {
    .margin-l(8/75rem);
    color: @sui_color_promo_dark;
    background: @sui_color_promo_bg;
    .font-dpr(24px);
    font-weight: 400;
    display: inline-block;
    padding: 2/75rem 8/75rem;
  }
}
.font {
  font-family: Adieu-Regular, Adieu;
  font-weight: 400;
}
</style>
